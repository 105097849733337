import ResourceList from "../../../services/ResourceList";
import SpriteCommon from "../common/SpriteCommon";
import InfoBox from "./InfoBox";

class InfoBoxWithIcon extends InfoBox {
    constructor(iconTextureID) {
        super();

        this.iconHolder = new SpriteCommon(iconTextureID);
        this.iconHolder.anchor.set(0, 0.5);
        this.iconHolder.y = this.bg.height / 2;

        this.iconHolder.x = this.margin;

        this.addChild(this.iconHolder);
    }

    /**
     * @access private
     * @param {number} r 
     * @param {number} a 
     * @returns {number}
     */
    getDiskOverMargin(r, barHeight) {
        return r - Math.sqrt(r**2 - (barHeight / 2)**2);
    }

    /**
     * @access public
     * @returns {x:number, y:number}
     */
    getIconCenter() {
        return {x: this.iconHolder.x + this.iconHolder.width / 2, y:this.bg.height / 2}
    }

    /**
     * @access public
     * @returns {x:number, y:number}
     */
    getIconOverhang() {
        return  this.iconHolder.x * this.scale.x;
    }

    /**
     * @access public
     */
    resizeLabel() {
        if (this.label) this.label.x = this.iconHolder.x + this.iconHolder.width + this.gap;
        this.labelShadow.x = this.label.x + 4;
        this.labelShadow.y = this.label.y + 2;
        const bgWidth = this.margin + this.iconHolder.width + this.gap + this.label.width + this.margin + this.shadowX;
        this.drawBg(bgWidth);
    }

}

export default InfoBoxWithIcon