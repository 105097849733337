import CryptoJS from "crypto-js";
import { AppConfig } from "../config/AppConfig";

class GameStat {
    constructor() {
        this.itemsHistory = [];
    }

    /**
     * @access package
     */
    resetStats() {
        this.itemsHistory = [];

    }

    /**
     * @access package
     */
    storeСaughtItem(itemKind) {
        this.itemsHistory.push(itemKind);
    }

    /**
     * @access package
     * @returns {string}
     */
    getItemsHistoryString() {
        let itemsString = "";
        this.itemsHistory.forEach(itemKind => {
            itemsString += (itemKind).toString();
        });

        const itemsImage = CryptoJS.AES.encrypt(itemsString, 'f0227a92774111eeb9620242ac120002').toString();
        return itemsImage
    }

    /**
    * @access package
    * @returns {{
    *   plus10: number,
    *   plus20: number,
    *   minusNpoints: number,
    *   minusNseconds: number,
    *   plusNseconds: number,
    *   magnet: number,
    *   speedUp: number
    * }} 
    */
    getItemsStat() {
        const shapes = AppConfig.SHAPE_TYPES;
        // const items = new Map(shapes.map(key => [key, 0]));

        const items = {};
        AppConfig.SHAPE_TYPES.forEach(key => {
            items[key] = 0;
        });

        this.itemsHistory.forEach(shapeType => {
            if (items.hasOwnProperty(shapeType)){
                items[shapeType]++;
            } else {
                const error = new Error("UNKNOWN_ITEM", "Unkonwn item error");
                console.log(error.message);
            }
            
        });
        return items
    }


}

export default GameStat