import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import { AppConfig } from '../../config/AppConfig';
import GameModel from '../../model/GameModel';
import EMessages from '../../services/EMessages';
import ResourceList from '../../services/ResourceList';
import SoundData from '../../services/SoundData';
import GameScreen from '../screens/GameScreen';
import StartButton from './StartButton';
import FixiedTilingSprite from './common/FixiedTilingSprite';
import SpriteCommon from './common/SpriteCommon';
import PointerGestures from '../../services/PointerGestures';
import ResourceService from '../../services/ResourceService';

class Countdown extends PIXI.Container {
    /**
     * 
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        const { gameWidth, gameHeight } = AppConfig.settings;
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;

        this.bg = new FixiedTilingSprite(ResourceList.BG_CELLS, -200);
        this.showTrainer =  this.gameModel.communictionService.shouldShowTutorial;
        //this.showTrainer = true;//TODO remove this
        this.gameModel.communictionService.tutorialStatusUpdated.add((shouldShow) => {
            this.showTrainer = shouldShow;

        });

        this.onResize = () => {

            let gameWidth, gameHeight, sc;
            if (!AppConfig.SCALE_SCENE) {
                gameWidth = AppConfig.settings.gameWidth;
                gameHeight = AppConfig.settings.gameHeight;
                sc = 1;
            } else {
                gameWidth = AppConfig.base.WIDTH;
                gameHeight = AppConfig.base.HEIGHT;
                sc =  AppConfig.settings.gameHeight / gameHeight;
            }

            // const { gameHeight } = AppConfig.settings;
            this.redrawBG();
            this.reglesButton.y = gameHeight / 2 - 50;
            this.startButton.y = this.reglesButton.y - 60;
            this.resezizeTrainer();
        }

        this.gameModel.onGesture.add((gesture) => {
            if (this.showTrainer){
                this.checkPassed(gesture);
            }
        })
        // AppConfig.sizeUpdated.add(this.onResize);
        
        this.addChild(this.bg);
        
        this.text3 = new SpriteCommon(ResourceList.COUNTDOWN_3);
        this.text2 = new SpriteCommon(ResourceList.COUNTDOWN_2);
        this.text1 = new SpriteCommon(ResourceList.COUNTDOWN_1);
        this.text0 = new SpriteCommon(ResourceList.COUNTDOWN_GO);

        this.startButton = new StartButton();
        this.startButton.y = 280;
        this.reglesButton = new SpriteCommon(ResourceList.MSC_BTN_REGLES);
        this.addChild(this.startButton);
        this.addChild(this.reglesButton);
        this.reglesButton.anchor.set(0.5, 0.5);
        this.reglesButton.eventMode = "dynamic";

        if (AppConfig.NO_PWA) {
            this.title = new SpriteCommon(ResourceList.HELLO_TITLE2);
        } else {
            this.title = new SpriteCommon(ResourceList.HELLO_TITLE);
        }
        
        this.title.eventMode = "none";
        this.title.scale.set(1, 1);
        this.title.anchor.set(0.5, 0.5);
        // this.title.y = gameHeight * 0.5;
        
        this.addChild(this.title);

        this.reglesButton.on('pointerdown', ()=> {
            this.gameModel.showRules = true;
        });
        
        this.startButton.on('pointertap', () => {
            this.emit('countdownStarted');
            this.text3.alpha = 0;
            const duration = AppConfig.gameSettings.counDownStepDuration / 2;
            gsap.timeline()
            .to(this.startButton, { alpha: 0, duration: duration, onStart: () => this.text3.visible = true })
            .call(() => {
                if (this.showTrainer && !AppConfig.FORCE_SKIP_TUTORIAL) {  //TODO remove true
                    this.startTrainer();
                } else {
                    this.startCountdown();
                }
                this.startButton.visible = false;
                this.title.visible = false;               
            }); 

            gsap.to(this.title, { alpha: 0, duration: duration});

          });

        this.addChild(this.text3, this.text2, this.text1, this.text0);
        this.addTrainer();

        this.text3.visible = false;
        this.text2.visible = false;
        this.text1.visible = false;
        this.text0.visible = false;

        this.text3.anchor.set(0.5);
        this.text2.anchor.set(0.5);
        this.text1.anchor.set(0.5);
        this.text0.anchor.set(0.5);

        this.text3.position.set(0, 0);
        this.text2.position.set(0, 0);
        this.text1.position.set(0, 0);
        this.text0.position.set(0, 0);

        this.onResize();

    }

    startTrainer() {
        this.gameModel.startTrainer();

        this.pointerGuide.visible = true;
        this.bg.eventMode = "passive";
        this.bg.eventMode = "passive";  
        this.bg.visible = false;
        this.pointerGuide._currentGesture = "none"
        this.pointerGuide.alpha = 0;
        this.tutorialCont.visible = true;

        this.trainerTasksStack = [];
        this.trainerTasksStack.push(PointerGestures.SLIDE_LEFT);
        this.trainerTasksStack.push(PointerGestures.SLIDE_RIGHT);
        this.trainerTasksStack.push(PointerGestures.SLIDE_DOWN);
        this.trainerTasksStack.push(PointerGestures.TAP);
        

        this.currentTrainerGesture = this.trainerTasksStack.shift();

        gsap.to(this.title, { alpha: 1, duration: 0.6, onComplete:()=> {
            // this.setTrainerByGesture(this.currentTrainerGesture);
            //this.currentTrainerGesture = this.trainerTasksStack[0];

        }});
        gsap.to(this.reglesButton, { alpha: 0, duration: 0.6, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
        }});

    }

    passTutorialDummy() {
        this.pointerGuide.visible = false;
        this.startCountdown();
        this.sendTutorialPassed();
    }

    checkPassed(gesture) {
        if (this.showTrainer == false) return

        if (this.currentTrainerGesture === gesture) {
            if (this.trainerTasksStack.length > 0) {
                const nextGesture = this.trainerTasksStack.shift();
                this.currentTrainerGesture = nextGesture;
            } else {
                // this.pointerGuide.visible = false;
                this.tutorialCont.visible = false;
                this.startCountdown();
                this.sendTutorialPassed(); 
            }        
        }
 

        if (this.trainerIntervalID > 0) clearTimeout(this.trainerIntervalID);
    }

    sendTutorialPassed() {
        this.gameModel.sendMessage(EMessages.SND_TUTORIAL_END);
    }
    /**
     * @access public
     */
    resetCountDown() {
        this.visible = true;
        this.bg.visible = true;
        this.alpha = 1;
        this.startButton.visible = true;
        this.startButton.alpha = 1;
        this.title.alpha = 1;
        this.visible = true;
        this.title.visible = true;
        this.reglesButton.visible = true;
        this.reglesButton.alpha = 1;
        this.tutorialCont.visible = false;
    }

    startCountdown() {
        const { counDownStepDuration } = AppConfig.gameSettings;
        const duration = counDownStepDuration;
        this.showTrainer = false;
        this.gameScreen.gamePlay.enterWaiting(false);
        // this.pointerGuide.visible = false;
        this.tutorialCont.visible = false;
        clearInterval(this.trainerIntervalID);
        gsap.to(this.reglesButton, { alpha: 0, duration: duration, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
            this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
        }});
        gsap.timeline()
          .to(this.text3, { alpha: 1, duration: duration, delay: duration })
          .to(this.text3, { alpha: 0, duration: duration, delay: duration })
          .to(this.text2, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text2.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text2, { alpha: 0, duration: duration, delay: duration })
          .to(this.text1, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text1.visible = true;
                this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text1, { alpha: 0, duration: duration, delay: duration })
          .to(this, { alpha: 0, duration: duration * 2, delay: duration })
          .call(() => {
            this.emit('countdownComplete');
            this.text3.visible = false;
            this.text2.visible = false;
            this.text1.visible = false;
            this.text0.visible = false;
            this.visible = false;
            this.gameScreen.soundManager.play(SoundData.MSC_START);
        }); 
    }

    redrawBG(){
        let gameWidth, gameHeight;     
        if (!AppConfig.SCALE_SCENE) {
            gameWidth = AppConfig.settings.gameWidth;
            gameHeight = AppConfig.settings.gameHeight;
        } else {
            const sc =  AppConfig.settings.gameHeight / AppConfig.base.HEIGHT;
            gameWidth = AppConfig.settings.gameWidth / sc;
            gameHeight = AppConfig.settings.gameHeight / sc;
        }

        this.bg.onResize(gameWidth, gameHeight, 'center', 'top');
        this.bg.width = gameWidth;
        this.bg.height = gameHeight;
        this.bg.x = - gameWidth / 2;
        this.bg.y = - gameHeight / 2;
        

    }

    addTrainer() {
        const { gameWidth, gameHeight } = AppConfig.settings;

        this.tutorialCont = new PIXI.Container();
        this.tutorialCont.visible = false;
        this.pointerGuide = new TreinerPointer();
        // this.pointerGuide.visible = false;
        
        this.tutorialTitle = new Text('TUTORIAL', { 
            fontFamily: 'MainBasketRun',
            fontSize: 30, 
            align: 'center',
            fill: 0xffffff });

        this.tutorialCaption = new Text('Desliza hacia la izquierda \no derecha para mover la \npieza', { 
            fontFamily: 'MainBasketRun',
            fontSize: 20, 
            align: 'center',
            fill: 0xffffff });
        
        this.trainerIntervalID = -1;
        // this.tutorialCaption.anchor.set(0.5, 0);
        // this.tutorialTitle.anchor.set(0, 0);

        this.addChild(this.tutorialCont);
        this.tutorialCont.addChild(this.pointerGuide);
        this.tutorialCont.addChild(this.tutorialCaption);
        this.tutorialCont.addChild(this.tutorialTitle);

        this.pointerGuide.eventMode = "passive";
        this.tutorialCaption.eventMode = "passive";
        this.tutorialTitle.eventMode = "passive";

        this.resezizeTrainer();
    }

    resezizeTrainer() {
        // const { gameWidth, gameHeight } = AppConfig.settings;

        let gameWidth, gameHeight;
        if (!AppConfig.SCALE_SCENE) {
            gameWidth = AppConfig.settings.gameWidth;
            gameHeight = AppConfig.settings.gameHeight;
        } else {
            gameWidth = AppConfig.base.WIDTH;
            gameHeight = AppConfig.base.HEIGHT;
        } 

        // const gamePlayTop = - this.gameScreen.gamePlay.height / 2;
        const gamePlayTop = this.gameScreen.gamePlay.y - gameHeight / 2;
        const gamePlayLeft = - AppConfig.base.WIDTH / 2 + this.gameScreen.gamePlay.x;
        const gamePlayCenter = gamePlayLeft + this.gameScreen.gamePlay.width / 2;
        const gamePlayBottom = gamePlayTop + this.gameScreen.gamePlay.height;


        this.tutorialCaption.x = gamePlayCenter - this.tutorialCaption.width / 2;
        this.tutorialCaption.y = gamePlayTop + this.gameScreen.gamePlay.height * 0.8 - this.tutorialCaption.height;
        this.tutorialTitle.x = gamePlayCenter - this.tutorialTitle.width / 2;
        this.tutorialTitle.y = gamePlayTop + 20;

        this.pointerGuide.x = gamePlayCenter - this.pointerGuide.width - 20;
        this.pointerGuide.y = gamePlayTop + this.gameScreen.gamePlay.height * 0.4 - this.tutorialCaption.height;

    }
    

    get currentTrainerGesture() {return this._currentTrainerGesture};
    set currentTrainerGesture (value) {
        if (this._currentTrainerGesture === value) return
        this._currentTrainerGesture = value
        this.pointerGuide.currentGesture = value;

        switch (value) {
            case PointerGestures.SLIDE_LEFT:
                // this.tutorialCaption.text = 'Desliza hacia la izquierda \no derecha para mover la \npieza';
                this.tutorialCaption.text = 'Desliza hacia la izquierda \npara mover la pieza';
                break;
            case PointerGestures.SLIDE_RIGHT:
                this.tutorialCaption.text = 'Desliza derecha la izquierda \npara mover la pieza';
                break;
            case PointerGestures.SLIDE_DOWN:
                this.tutorialCaption.text = 'Desliza hacia abajo para \nque baje mas rápido \nla pieza';                break;
            case PointerGestures.TAP:
                this.tutorialCaption.text = 'Desliza hacia la izquierda \no derecha para mover \nla pieza';                break;
            default:
                break;
        }

        // this.setTrainerByGesture(value);

    }  

    
}


class TreinerPointer extends PIXI.Sprite {
    constructor() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        super(PIXI.Texture.EMPTY);
        this._currentGesture = "none";
        this.iconGesture = new SpriteCommon(ResourceList.MSC_CART_CLICKER_LEFT);

        this.textureLeftRight = ResourceService.getTexture(ResourceList.MSC_TRAINER_ICON_LEFTRIGHT);
        this.textureLeft = ResourceService.getTexture(ResourceList.MSC_TRAINER_ICON_LEFT);
        this.textureRight = ResourceService.getTexture(ResourceList.MSC_TRAINER_ICON_RIGHT);
        this.textureDown = ResourceService.getTexture(ResourceList.MSC_TRAINER_ICON_DOWN);
        this.textureTouch = ResourceService.getTexture(ResourceList.MSC_TRAINER_ICON_TAP);
        
        this.addChild(this.iconGesture);
        this.iconGesture.eventMode = "passive";
        // this.iconGesture.anchor.set(0.5, 0.5);

        this.eventMode = "passive";
        this._phase = Math.PI / 2;
        this.anchor.set(0.5, 0.5);
        this.y = 100;    
        
    }

    get phase() {return this._phase};
    set phase (value) {
        if (this._phase === value) return
        this._phase = value;
        this.alpha = Math.abs(Math.cos(value))
    }

    get currentGesture() {return this._currentGesture};
    set currentGesture (value) {
        if (this._currentGesture === value) return
        
        const duration = 0.7;
        const delay = 0.3;
        this.iconGesture.alpha = 1;
        let nextIconTexture;
        switch (value) {
            case PointerGestures.SLIDE_LEFT:
                nextIconTexture = this.textureLeft;
                break;
            case PointerGestures.SLIDE_RIGHT:
                nextIconTexture = this.textureRight;
                break;
            case PointerGestures.SLIDE_DOWN:
                nextIconTexture = this.textureDown;
                break;
            case PointerGestures.TAP:
                nextIconTexture = this.textureTouch;
                break;
            default:
                break;
        }
        
        // this.iconGesture.texture  = nextIconTexture;

        if (this._currentGesture !== "none") {
            gsap.to(this, { alpha: 0, duration: duration, delay: delay, 
                onComplete: () => {
                this.iconGesture.texture  = nextIconTexture;
                this.anymationGeture(value);
            }});
        } else {
            this.iconGesture.texture  = nextIconTexture;
            gsap.to(this, { alpha: 1, duration: duration, delay: delay, 
                onComplete: () => {
                    this.anymationGeture(value);
            }});
        }

        
        this._currentGesture = value;

    }  

    anymationGeture(gesture) {
        let duration = 0.2;
        let delay = 0;
        let zoomMax = 1.2;
        let zoomMin = 0.8;
        let moveDist = AppConfig.settings.gameWidth / 5;
        let ease1 = "power2.in";
        let ease2 = "power2.out";
        this.iconGestureTween;
        gsap.globalTimeline.clear();
        switch (gesture) {
            case PointerGestures.SLIDE_LEFT:
                this.iconGestureTween = gsap.timeline()
                .to(this, { alpha: 1, duration: duration, delay: delay})
                .to(this.iconGesture, { x: -moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: -moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: -moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: -moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: -moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
  
                break;
            case PointerGestures.SLIDE_RIGHT:
                this.iconGestureTween = gsap.timeline()
                .to(this, { alpha: 1, duration: duration, delay: delay})
                .to(this.iconGesture, { x: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { x: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { x: 0, duration: duration, delay: duration, ease:ease2})
                break;
            case PointerGestures.SLIDE_DOWN:
                this.iconGestureTween = gsap.timeline()
                .to(this, { alpha: 1, duration: duration, delay: delay})
                .to(this.iconGesture, { y: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { y: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { y: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { y: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { y: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { y: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { y: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { y: 0, duration: duration, delay: duration, ease:ease2})
                .to(this.iconGesture, { y: moveDist, duration: duration, delay: duration, ease:ease1})
                .to(this.iconGesture, { y: 0, duration: duration, delay: duration, ease:ease2})
                break;
            case PointerGestures.TAP:
                this.iconGestureTween = gsap.timeline()
                .to(this, { alpha: 1, duration: duration, delay: delay})
                .to(this.scale, { x: zoomMax, y: zoomMax, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMin, y: zoomMin, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMax, y: zoomMax, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMin, y: zoomMin, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMax, y: zoomMax, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMin, y: zoomMin, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMax, y: zoomMax, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: zoomMin, y: zoomMin, duration: duration, delay: delay, ease:ease1})
                .to(this.scale, { x: 1, y: 1, duration: duration, delay: duration})
                break;
            default:
                break;
        }
    }


}


/*
class TreinerPointer extends PIXI.Sprite {
    constructor() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        super(PIXI.Texture.EMPTY);
        this.iconLeftRightGesture = new SpriteCommon(ResourceList.MSC_CART_CLICKER_LEFT);
        this.iconDownGesture = new SpriteCommon(ResourceList.MSC_CART_CLICKER_CENTER);
        this.iconTouchGesture = new SpriteCommon(ResourceList.MSC_CART_CLICKER_RIGHT);
        this.addChild(this.iconLeftRightGesture);
        this.addChild(this.iconDownGesture);
        this.addChild(this.iconTouchGesture);
        // this.mouseIconLeft.alpha = 1;
        // this.mouseIconCenter.alpha = 0;
        // this.mouseIconRight.alpha = 0; 

        this.iconLeftRightGesture.eventMode = "passive";
        this.iconDownGesture.eventMode = "passive";
        this.iconTouchGesture.eventMode = "passive";

        this.iconLeftRightGesture.anchor.set(0.5, 0.5);
        this.iconDownGesture.anchor.set(0.5, 0.5);
        this.iconTouchGesture.anchor.set(0.5, 0.5);

        this._iconPos = 0;
        this.iconPos = -1;

        this.eventMode = "passive";
        this._phase = Math.PI / 2;
        this.anchor.set(0.5, 0.5);
        this.y = 100;       
    }

    get phase() {return this._phase};
    set phase (value) {
        if (this._phase === value) return
        this._phase = value;
        this.alpha = Math.abs(Math.cos(value))
    }

    get iconPos() {return this._iconPos};
    set iconPos (value) {
        if (this._iconPos === value) return
        this._iconPos = value;
        const duration = 0.7;
        const delay = 0.75;
        if (value === -1) {
            
            this.iconLeftRightGesture.alpha = 0;
            gsap.to(this.iconLeftRightGesture, { alpha: 1, duration: duration, delay: delay });
            gsap.to(this.iconDownGesture, { alpha: 0, duration: duration, delay: delay });
            gsap.to(this.iconTouchGesture, { alpha: 0, duration: duration, delay: delay });
        }
        if (value === 0) {
            this.iconDownGesture.alpha = 0;
            gsap.to(this.iconLeftRightGesture, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.iconDownGesture, { alpha: 1, duration: duration, delay: delay});
            gsap.to(this.iconTouchGesture, { alpha: 0, duration: duration, delay: delay});
        }
        if (value === 1) {
            this.iconTouchGesture.alpha = 0;
            gsap.to(this.iconLeftRightGesture, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.iconDownGesture, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.iconTouchGesture, { alpha: 1, duration: duration, delay: delay});
        }
    }  


} 

*/




export default Countdown