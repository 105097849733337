import * as PIXI from 'pixi.js';
import ResourceList from "../../../services/ResourceList"
import ParticleEmitter from "./ParticleEmitter"
import EItemType from '../../../model/EItemType';

class ParticleEmitterFactory {

    /**
     * 
     * @param {PIXI.Sprite} screen 
     * @returns 
     */
    static initTopEmitter(screen) {
        const speedLineTex= PIXI.Texture.from(ResourceList.MSC_SPEEDLINE);
        const shockTexArray= new Array().fill(0).map((el, i) => {
          const link = `./assets/misc/speedline${i}.png`;
          return PIXI.Texture.from(link);
        })
    
        const emitter = new ParticleEmitter();
    
        screen.speedUpEffectConfig = emitter.add('speedFX', {
          life: [1, 2],
          birthrate: 0.1,
          width: 1,
          height: 1,
          colors: [0xffffff],
          textures: [speedLineTex],
          alpha: [0.5, 0.8],
          alphaOverLife: [0, 1, 0],
          scale: [0.1, 0.3],
          scaleOverLife: [0, 5],
          oriented: true,
          velocity: 10,
          vector: new PIXI.Point(0, 0.5),
          velocityOverLife: [1, 5],
        })
    
        return emitter;
    }

    /**
     * 
     * @param {PIXI.Sprite} screen 
     * @returns 
     */
    static initEmitter(screen) {
        const starTex= PIXI.Texture.from(ResourceList.MSC_STAR)
        const star4Tex= PIXI.Texture.from(ResourceList.MSC_STAR4)
        const dropTex= PIXI.Texture.from(ResourceList.MSC_DROP)
        const shimmerTex= PIXI.Texture.from(ResourceList.MSC_SHIMMER)
        const circleTex= PIXI.Texture.from(ResourceList.MSC_CIRCLE)
        const fadeCircleTex= PIXI.Texture.from(ResourceList.MSC_FADE_CIRCLE)
        const magnetTex= PIXI.Texture.from(ResourceList.MSC_MINUS)
        const lightningTex= PIXI.Texture.from(ResourceList.MSC_MINUS)
        const timeMinusTex= PIXI.Texture.from(ResourceList.MSC_MINUS)
        const timePlusTex= PIXI.Texture.from(ResourceList.MSC_MINUS)
        const minusTex= PIXI.Texture.from(ResourceList.MSC_MINUS)
        const plusTex= PIXI.Texture.from(ResourceList.MSC_PLUS)
  
        const emitter = new ParticleEmitter();
        emitter.add('stars', {
          life: [0.3, 0.7],
          width: 50,
          height: 50,
          colors: [0xffffff],
          textures: [starTex],
          scale: [0.3, 0.5],
          scaleOverLife: [1, 1.5, 0],
          velocity: 2,
          velocityOverLife: [2, 0],
          rotation: [-Math.PI, Math.PI],
          rotationVelocity: [-0.1, 0.3],
        })
  
        const timeConfig = emitter.add('time', {
          life: [0.3, 0.7],
          width: 50,
          height: 50,
          colors: [0xffffff],
          textures: [minusTex],
          scale: [0.5, 0.8],
          scaleOverLife: [1, 1.5, 0],
          velocity: 2,
          velocityOverLife: [2, 0],
          rotation: [-Math.PI * 0.2, Math.PI * 0.2],
          rotationVelocity: [-0.1, 0.1],
        })
  
        emitter.add('drops', {
          life: [0.3, 0.7],
          width: 50,
          height: 30,
          colors: [0xffffff],
          textures: [dropTex],
          scale: [0.3, 0.5],
          scaleOverLife: [1, 1.5, 0],
          velocity: 4,
          vectors: [new PIXI.Point(0, -2)],
          velocityOverLife: [2, 0],
          forceVectors: [new PIXI.Point(0, 1)],
          force: 1,
          forceOverLife: [0, 2, 2],
          rotation: [-Math.PI, Math.PI],
          rotationVelocity: [-0.2, 0.2],
        })
  
        emitter.add('shimmer', {
          life: [0.2, 0.4],
          width: 10 ,
          height: 10,
          colors: [0xffffff],
          textures: [shimmerTex, shimmerTex, fadeCircleTex],
          scale: [0.3, 0.6],
          scaleOverLife: [1.5,1, 1.2],
          velocity: 0,
          alphaOverLife: [0.7, 0],
          rotation: [-Math.PI, Math.PI],
          rotationVelocity: [-0.5, 0.5],
        })
  
        emitter.add('shimmerStars', {
          life: [0.3, 0.6],
          width: 80,
          height: 80,
          colors: [0xffffff, 0xF6E2B8, 0xF5AEC3],
          textures: [star4Tex, fadeCircleTex],
          scale: [0.3, 0.5],
          scaleOverLife: [1, 1.4, 0],
          velocity: 3,
          velocityOverLife: [2, 0.1],
          // alphaOverLife: [0.7, 0],
          rotation: [-Math.PI, Math.PI],
          rotationVelocity: [-0.5, 0.5],
          force: 2,
          forceVectors: [new PIXI.Point(0, 1)],
          forceOverLife: [0.2, 2]
        })
  
        emitter.add('badCircles', {
          life: [0.3, 0.6],
          width: 60,
          height: 40,
          colors: [0xE6EEF2, 0xF5B865, 0xE8231B,0xE8231B,0xF6382B, 0xFB5A45],
          textures: [circleTex],
          scale: [0.4, 0.7],
          scaleOverLife: [1, 1.5, 0],
          velocity: 3,
          velocityOverLife: [2, 0.1],
          // alphaOverLife: [0.7, 0],
          rotation: [-Math.PI, Math.PI],
          rotationVelocity: [-0.5, 0.5],
          // twirlForce: [1, 1.2],
        })
  
        const powerupsConfig = emitter.add('powerUps', {
          life: [0.3, 0.5],
          width: 60,
          height: 40,
          colors: [0xffffff],
          textures: [magnetTex],
          scale: [0.2, 0.4],
          scaleOverLife: [1, 1.2, 0],
          velocity: 3,
          vector: new PIXI.Point(0, -2),
          velocityOverLife: [2, 0.1],
          alpha: [0.7, 0.9],
          rotation: [-Math.PI * 0.3, Math.PI * 0.3],
          rotationVelocity: [-0.1, 0.1],
          force: 2,
          forceVectors: [new PIXI.Point(0, 2)],
          forceOverLife: [0, 2]
          // twirlForce: [1, 1.2],
        })
  
        screen.on('burstParticles', (x, y, type) => {
          switch (type.itemType) {
            case EItemType.TIME:
                          if (type.kindness === 'good') {
                            timeConfig.textures = [plusTex]
                            emitter.start(x, y,  12, 'time');
                          }
            else {
                            emitter.start(x, y,  8, 'badCircles');
                            timeConfig.textures = [minusTex]
                            emitter.start(x, y,  10, 'time');
                          }
              break;
            case EItemType.MAGNET:
              emitter.start(x, y,  10, 'stars');
              powerupsConfig.textures = [magnetTex]
              emitter.start(x, y,  5, 'powerUps');
              break;
  
            case EItemType.SPEED_UP:
              emitter.start(x, y,  10, 'stars');
              powerupsConfig.textures = [lightningTex]
              emitter.start(x, y,  5, 'powerUps');
              break;
  
            case EItemType.SCORES:
              if (type.kindness === 'good') {
                emitter.start(x, y + 20,  8, 'shimmer');
                emitter.start(x, y + 20,  7, 'shimmerStars');
              }
           else {
                emitter.start(x, y,  10, 'drops');
              }
              break;
            default:
          }
        })
  
        return emitter;
      }
}

export default ParticleEmitterFactory