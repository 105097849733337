import ResourceList from "../../../../services/ResourceList";
import RunPowerUpAbstract from "./RunPowerUAbstract";

class RunPowerUpSlime extends RunPowerUpAbstract {
    constructor(gameModel) {
        super(ResourceList.CTRL_SLIME_SHAPE, gameModel);
        this.shapeId = "a";
    }
    updateFromModel(){
        super.updateFromModel();
        this.itemsNum = this.gameModel.powerUps.slime;
    }
}

export default RunPowerUpSlime;