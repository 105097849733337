import ResourceList from "../../../../services/ResourceList";
import RunPowerUpAbstract from "./RunPowerUAbstract";

class RunPowerUpSingleShape extends RunPowerUpAbstract {
    constructor(gameModel) {
        super(ResourceList.CTRL_SINGLE_SHAPE, gameModel);
        this.shapeId = "x";
    }
    updateFromModel(){
        super.updateFromModel();
        this.itemsNum = this.gameModel.powerUps.block1x;
    }
}

export default RunPowerUpSingleShape;