import ResourceList from "../../../../services/ResourceList";
import RunPowerUpAbstract from "./RunPowerUAbstract";

class RunPowerUpFiveX extends RunPowerUpAbstract {
    constructor(gameModel) {
        super(ResourceList.CTRL_FIVEX_SHAPE, gameModel);
        this.shapeId = "y";
    }

    updateFromModel(){
        super.updateFromModel();
        this.itemsNum = this.gameModel.powerUps.block5x;
    }


}

export default RunPowerUpFiveX;