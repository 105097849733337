import { AppConfig } from "../config/AppConfig";
import { SHAPE_COLORS } from "../tetris/play/Tetromino";

class ResourceList {
}


ResourceList.START_BUTTON = `./assets/start-button.png`;
ResourceList.HELLO_TITLE = `./assets/hello-title.png`;
ResourceList.HELLO_TITLE2 = `./assets/hello-title2.png`;
ResourceList.BG_CELLS = `./assets/bg-cells.png`;
ResourceList.GAME_OVER_SCREEN = `./assets/game-over/game-over-screen.png`;



ResourceList.COUNTDOWN_1 = `./assets/countdown/count1.png`;
ResourceList.COUNTDOWN_2 = `./assets/countdown/count2.png`;
ResourceList.COUNTDOWN_3 = `./assets/countdown/count3.png`;
ResourceList.COUNTDOWN_GO = `./assets/countdown/count-go.png`;

ResourceList.MSC_BTN_MUTE = `./assets/misc/btn_mute.png`;
ResourceList.MSC_BTN_UNMUTE = `./assets/misc/btn_unmute.png`;
ResourceList.MSC_BTN_CLOSE = `./assets/misc/btn_close.png`;
ResourceList.MSC_BTN_PAUSE = `./assets/misc/btn_pause.png`;
ResourceList.MSC_COIN = `./assets/misc/coin.png`;
ResourceList.MSC_TRAIL = `./assets/misc/trail.png`;

ResourceList.EFFECTS = `./assets/effects.json`;

/* ResourceList.ITEM_MAGNET = `./assets/items/item-magnet.png`;
ResourceList.ITEM_SPEEDUP = `./assets/items/item-speedup.png`;
ResourceList.ITEM_TIMEPLUS = `./assets/items/item-timeplus.png`;
ResourceList.ITEM_TIMEMINUS = `./assets/items/item-timeminus.png`;
ResourceList.ITEM_BAD = `./assets/items/item-bad.png`; */

ResourceList.MSC_COIN = `./assets/misc/coin.png`;
ResourceList.MSC_STAR = `./assets/misc/star.png`;
ResourceList.MSC_MINUS = `./assets/misc/minus.png`;
ResourceList.MSC_PLUS = `./assets/misc/plus.png`;
ResourceList.MSC_STAR4 = `./assets/misc/star4.png`;
ResourceList.MSC_DROP = `./assets/misc/drop.png`;
ResourceList.MSC_SHIMMER = `./assets/misc/shimmer.png`;
ResourceList.MSC_SPEEDLINE = `./assets/misc/speedline.png`;
ResourceList.MSC_FADE_CIRCLE = `./assets/misc/fade_circle.png`;
ResourceList.MSC_CIRCLE = `./assets/misc/circle.png`;

ResourceList.MSC_CART_CLICKER = `./assets/misc/cart-click.png`;
ResourceList.MSC_CART_CLICKER_LEFT = `./assets/misc/cart-click-left.png`;
ResourceList.MSC_CART_CLICKER_CENTER = `./assets/misc/cart-click-center.png`;
ResourceList.MSC_CART_CLICKER_RIGHT = `./assets/misc/cart-click-right.png`;

ResourceList.MSC_TRAINER_ICON_LEFTRIGHT = `./assets/misc/trainer-gesture-leftright.png`;
ResourceList.MSC_TRAINER_ICON_LEFT = `./assets/misc/trainer-gesture-left.png`;
ResourceList.MSC_TRAINER_ICON_RIGHT = `./assets/misc/trainer-gesture-right.png`;
ResourceList.MSC_TRAINER_ICON_DOWN = `./assets/misc/trainer-gesture-down.png`;
ResourceList.MSC_TRAINER_ICON_TAP = `./assets/misc/trainer-gesture-tap.png`;


ResourceList.MSC_BTN_REGLES = `./assets/misc/btn-regles.png`;
ResourceList.BANNER_TOP = `./assets/banners/top-banner.png`;
ResourceList.BANNER_BOTTOM = `./assets/banners/bottom-banner.png`;

ResourceList.TETRIS_SPRITES = `./assets/tetris/sprites-27.json`;
ResourceList.CTRL_FIVEX_SHAPE = `./assets/controls/five-x.png`;
ResourceList.CTRL_SINGLE_SHAPE = `./assets/controls/single-x.png`;
ResourceList.CTRL_SLIME_SHAPE = `./assets/controls/slime.png`;
ResourceList.CTRL_ITEMS_NUM_BG = `./assets/controls/itemsnum-bg.png`;

ResourceList.SHAPE_YELLO = `./assets/shapes/yellow.png`;

ResourceList.SHAPE_BASE = `./assets/shapes/shape-`;
ResourceList.NEXT_SHAPES = [];

AppConfig.SHAPE_TYPES.map(shape => {
    ResourceList.NEXT_SHAPES.push(`./assets/shapes/shape-${shape}.png`);
});



ResourceList.CUSTOM_RESOURCES = [];

ResourceList.LIST = [

    // ResourceList.HIT_AREA,

    ResourceList.START_BUTTON,
    ResourceList.HELLO_TITLE,
    ResourceList.HELLO_TITLE2,
    ResourceList.BG_CELLS,
    ResourceList.GAME_OVER_SCREEN,

    ResourceList.COUNTDOWN_1,
    ResourceList.COUNTDOWN_2,
    ResourceList.COUNTDOWN_3,
    ResourceList.COUNTDOWN_GO,

    ResourceList.MSC_BTN_MUTE,
    ResourceList.MSC_BTN_UNMUTE,
    ResourceList.MSC_BTN_CLOSE,
    ResourceList.MSC_BTN_PAUSE,
    ResourceList.MSC_COIN,

    ResourceList.MSC_CART_CLICKER,
    ResourceList.MSC_CART_CLICKER_LEFT,
    ResourceList.MSC_CART_CLICKER_CENTER,
    ResourceList.MSC_CART_CLICKER_RIGHT,
    ResourceList.MSC_TRAINER_ICON_LEFTRIGHT,
    ResourceList.MSC_TRAINER_ICON_LEFT,
    ResourceList.MSC_TRAINER_ICON_RIGHT,
    ResourceList.MSC_TRAINER_ICON_DOWN,
    ResourceList.MSC_TRAINER_ICON_TAP,
    ResourceList.MSC_BTN_REGLES,
    ResourceList.MSC_TRAIL,

    ResourceList.BANNER_TOP,
    ResourceList.BANNER_BOTTOM,

    ResourceList.TETRIS_SPRITES,

    ResourceList.CTRL_FIVEX_SHAPE,
    ResourceList.CTRL_SINGLE_SHAPE,
    ResourceList.CTRL_SLIME_SHAPE,
    ResourceList.CTRL_ITEMS_NUM_BG,

    ResourceList.SHAPE_YELLO,
  
    ...ResourceList.CUSTOM_RESOURCES,
];


// const skinArray = ResourceList["LIST_GAME" + AppConfig.gameID]

ResourceList.LIST = ResourceList.LIST.concat(ResourceList.NEXT_SHAPES);
// ResourceList.LIST = ResourceList.LIST.concat(skinArray);

//const spritesheet = PIXI.Assets.get("assets/tetris/sprites.json");


export default ResourceList;
