import * as PIXI from 'pixi.js';
import ProgressBar from '../ProgressBar';
import TimeLeftProgressBar from '../progresses/TimeLeftProgressBar';
import MagnetProgress from '../progresses/MagnetProgress';
import SpeedUpProgress from '../progresses/SpeedUpProgress';
import { AppConfig } from '../../../config/AppConfig';
import EItemsID from '../../../model/EItemsID';
import ScoreInfo from './ScoreInfo';
import gsap from "gsap";
import SpriteCommon from '../common/SpriteCommon';
import ResourceList from '../../../services/ResourceList';
import GameModel, { EGameStates } from '../../../model/GameModel';
import TimeLeftInfoBox from './TimeLeftInfoBox';
import GameScreen from '../../screens/GameScreen';
import EMessages from '../../../services/EMessages';
import NextShapeInfo from './NextShapeInfo';
import RunPowerUpFiveX from './controls/RunPowerUpFiveX';
import RunPowerUpSingleShape from './controls/RunPowerUpSingleShape';
import RunPowerUpSlime from './controls/RunPowerUpSlime';
import ResourceService from '../../../services/ResourceService';

class PanelInfo extends PIXI.Container {
    /**
     * 
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;


        this.scoreInfo = new ScoreInfo();
        this.btnMuteCont = new PIXI.Container();
        this.btnMute = new SpriteCommon(ResourceList.MSC_BTN_MUTE);
        this.btnUnMute = new SpriteCommon(ResourceList.MSC_BTN_UNMUTE);
        if (AppConfig.NO_PWA) {
            this.btnClose = new SpriteCommon(ResourceList.MSC_BTN_PAUSE);
        } else {
            this.btnClose = new SpriteCommon(ResourceList.MSC_BTN_CLOSE);
        }
        

        this.playTexture = ResourceService.getTexture(ResourceList.MSC_BTN_CLOSE);
        this.pauseTexture = ResourceService.getTexture(ResourceList.MSC_BTN_PAUSE);
        
        this.nextShapeInfo = new NextShapeInfo(gameModel);
        this.btnShapeFiveX = new RunPowerUpFiveX(gameModel);
        this.btnSingleShape = new RunPowerUpSingleShape(gameModel);
        this.btnSlime = new RunPowerUpSlime(gameModel);
/*         this.btnShapeFiveX = new SpriteCommon(ResourceList.CTRL_FIVEX_SHAPE);
        this.btnSingleShape = new SpriteCommon(ResourceList.CTRL_SINGLE_SHAPE);
        this.btnSlime = new SpriteCommon(ResourceList.CTRL_SLIME_SHAPE); */

        this.btnShapeFiveX.anchor.set(1, 1);
        this.btnSingleShape.anchor.set(1, 1);
        this.btnSlime.anchor.set(1, 1);

        this.updateScores = (item, scores) => {
            const { levelMaxScores } = AppConfig.gameSettings;
            this.scoreInfo.setScores(this.gameModel.scores + this.gameModel.balance);
            // this.scoreInfo.resizeLabel();
        };
        

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.btnClose.alpha = 1;
            } else {
                this.btnClose.alpha = 1;
            }
        };

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.btnClose.texture = this.pauseTexture
            } else {
                this.btnClose.texture = this.playTexture;
            }
        };
        
        this.onGameStateUpdated();


        this.onResize = () => {
            const { gameWidth, gameHeight } = AppConfig.settings;

            let scoreInfoSize = 230;
            this.scoreInfo.setComponentWidth(scoreInfoSize); 

            const vGaps = this.gameScreen.vGaps;
            
            this.scoreInfo.x = this.gameScreen.gamePlay.x;
            this.scoreInfo.y = this.gameScreen.topBanner.y + this.gameScreen.topBanner.height + vGaps;
            // this.scoreInfo.resizeLabel();

            

            this.btnClose.x = gameWidth - this.btnClose.width + 5;
            this.btnMuteCont.x = gameWidth - this.btnMute.width + 5;
            this.btnClose.y = this.scoreInfo.y;
            this.btnMuteCont.y = this.btnClose.y + this.btnClose.height + vGaps;
            
            this.btnSingleShape.x = this.gameScreen.topBanner.x + this.gameScreen.topBanner.width;
            this.btnShapeFiveX.x = this.btnSingleShape.x;
            this.btnSlime.x = this.btnShapeFiveX.x;

            this.nextShapeInfo.x = this.btnSingleShape.x - this.nextShapeInfo.width;
            this.nextShapeInfo.y = this.gameScreen.gamePlay.y;


            this.btnSingleShape.y = this.gameScreen.gamePlay.y + this.gameScreen.gamePlay.height;
            this.btnShapeFiveX.y = this.btnSingleShape.y - 70;
            this.btnSlime.y = this.btnShapeFiveX.y - 70;
        };

        this.onSoundManagerStatusUpdated = (isOn) => {
            if (this.gameScreen.soundManager.isSoundOn) {
                this.btnMute.visible = false;
                this.btnUnMute.visible = true;
            } else {
                this.btnMute.visible = true;
                this.btnUnMute.visible = false;
            }
        }

        this.gameModel.scoreUpdated.add(this.updateScores);
        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameScreen.soundManager.statusUpdated.add(this.onSoundManagerStatusUpdated);

        // AppConfig.sizeUpdated.add(this.onResize);

        this.addChildren();
        // this.onResize();
        this.addButtons();
        this.updateScores();
 
    }

    addChildren() {
        const { gameWidth, gameHeight  } = AppConfig.settings;
        this.addChild(this.scoreInfo);
        this.scoreInfo.x = gameWidth - this.scoreInfo.width - 100;

        this.addChild(this.nextShapeInfo);

        this.addChild(this.btnShapeFiveX);
        this.addChild(this.btnSingleShape);
        this.addChild(this.btnSlime);
    }

    addButtons() {
        this.btnClose.anchor.set(1, 0);
        this.btnMute.anchor.set(1, 0);
        this.btnUnMute.anchor.set(1, 0);

        this.btnClose.eventMode = "dynamic";
        this.btnMuteCont.eventMode = "dynamic";

        this.btnClose.cursor = "pointer";
        this.btnMuteCont.cursor = "pointer";


        this.btnClose.on('pointerdown', () => {
            this.gameModel.registerCloseInteraction();
        }); 
        
        this.btnMuteCont.on('pointerdown', () => {
            this.gameScreen.soundManager.isSoundOn = !this.gameScreen.soundManager.isSoundOn;
        });
        this.onSoundManagerStatusUpdated();

    }

}

export default PanelInfo