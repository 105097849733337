import PointerGestures from "../../services/PointerGestures";
import Tetromino from "./Tetromino";

/**
 * Tetris grid model
 */
export default class Board {
    constructor(rows, cols) {
        this.rows = rows;
        this.cols = cols;
        this.grid = [];
        
        for (let i = 0; i < this.rows; ++i) {
            let row = [];
            for (let j = 0; j < this.cols; ++j) {
                row.push(false);
            }
            this.grid.push(row);
        }
    }
    
    /**
     * Test if any provided position is not empty
     * @param   {Array} positions list of positions in form [row, col]
     * @returns {boolean}  collision status
     */
    collides(positions) {
        let row, col;
        for(let i = 0; i < positions.length; ++i) {
            row = positions[i][0];
            col = positions[i][1];
            if (row < 0 || row >= this.rows ||
                col < 0 || col >= this.cols ||
                this.grid[row][col]) {
                
                return true;
            }
        }
        return false;
    }
    
    /**
     * Test if row is full
     * @param   {Number} row tested row
     * @returns {Number} test result
     */
    isFull(row) {
        return this.grid[row].every(cell => !!cell);
    }
    
    /**
     * Set provided value in board grid for each given position.
     * Additionally, for each changed row, test if its full.
     * @param   {Array} positions list of positions in form [row, col]
     * @param   {Object} val value to set, like block colour name
     * @returns {Array} list of full rows
     */
    setAll(positions, val) {
        let i,row,col,block;
        let rowsToCheck = new Set();
        for(i=0; i < positions.length; ++i) {
            this.grid[positions[i][0]][positions[i][1]] = val;
            rowsToCheck.add(positions[i][0]);
        }
        return Array.from(rowsToCheck).filter(this.isFull, this);
    }
    
    /**
     * Clear full rows and move remaining blocks down.
     * @param {Array} rows indexes to clear
     */
    cleanRows(rows) {
        rows.sort((a,b) => a - b);
        let emptyRows = [];
        for (let i = rows.length - 1; i >= 0; --i) {
            this.grid.splice(rows[i], 1);
            let row = [];
            for (let j = 0; j < this.cols; ++j) {
                row.push(false);
            }
            emptyRows.push(row);
        }
        Array.prototype.unshift.apply(this.grid, emptyRows);
    }

    slime(rowNum, colNum) {
        const absolutePos = [];
        for (let i = rowNum; i < this.grid.length; i++) {
            let row = this.grid[i];
            if (i == rowNum) {
                // row[colNum] = false;
            } else if (!row[colNum]) {
                row[colNum] = "green";
                absolutePos.push([i, colNum]);
            }
            
        }
        return absolutePos;
    }
    
    set(row, col, val) {
        this.grid[row][col] = val;
        return (this.isFull(row)) ? [row] : [];
    }
    
    get(row, col) {
        if (row >= 0 && row < this.rows && col >= 0 && col < this.cols) {
            return this.grid[row][col];
        } else {
            return null;
        }
    }

    /**
     * 
     * @param   {number} rowNum 
     * @param   {Tetromino} shape 
     * @param   {string} gesture 
     * @returns {number}  maxDist - distance of the geture
     */
    distanseToClosestFromShape(shape, maxDist, gesture) {
        let xDirection, yDirection = 0;
        switch (gesture) {
            case PointerGestures.SLIDE_LEFT:
                xDirection = -1;
                yDirection = 0; 
                break;
            case PointerGestures.SLIDE_RIGHT:
                xDirection = 1;
                yDirection = 0; 
                break;
            case PointerGestures.SLIDE_DOWN:
                xDirection = 0;
                yDirection = 1; 
                break;
            default:
                xDirection = 0;
                yDirection = 0; 
                break;
        }


        for (let d = 1; d <= maxDist + 1; d++ ) {
            const shapePoints = shape.absolutePos(yDirection * d , xDirection * d);
            const isCollide = this.collides(shapePoints);
            if (isCollide) return d - 1
        }
        return maxDist; //never acieves

    }


    

    /**
     * 
     * @param   {number} rowNum 
     * @param   {number} colNum 
     * @returns {number} maxDist
     */
    distanseToClosestLeftFromPoint(rowNum, colNum, maxDist) {
        const max = maxDist != undefined ? maxDist : this.cols - 1;
        let row = this.grid[rowNum];
        let dist = 0;
        for (let i = colNum - 1; i >= 0; i--) {
            if (row[i] || i == max) {
                return dist
            }
            dist++;
        }
        return dist;

    }


}
