import * as PIXI from 'pixi.js';
import GameModel, { EGameStates } from '../../model/GameModel';
import { AppConfig } from '../../config/AppConfig';
import GamePlay from '../../tetris/play/GamePlay';
import DebugService from '../../services/DebugService';
import ResourceService from '../../services/ResourceService';
import ResourceList from '../../services/ResourceList';
import gsap from "gsap";
import { Utils } from '../utils/Utils';
import PointerGestures from '../../services/PointerGestures';


class TouchArea extends PIXI.Container {
    /**
     * @param {GameModel} gameModel 
     * @param {GamePlay} gamePlay 
     * @param {number} w - width
     * @param {number} h 
     */
    constructor(gameModel, gamePlay, w, h){
        super();
        this.w = w;
        this.h = h;
        this.isMobile = this.isMobileDevice();
        this.eventMode = 'dynamic';
        this.gameModel = gameModel;
        this.gamePlay = gamePlay;
        this.boradRect = new PIXI.Rectangle(0, 0, w, h);

        this.startPoint;
        this.endPoint;

        this.historyX = [];
        this.historyY = [];
        this.historySize = 10;
        this.ropeSize = 30;
        this.points = [];
        this.slidePoints;

        for (let i = 0; i < this.historySize; i++){
            this.historyX.push(0);
            this.historyY.push(0);
        }

        for (let i = 0; i < this.ropeSize; i++){
            this.points.push(new PIXI.Point(300, 300));
        }

        this.mouseposition = null;

        this.box = new PIXI.Graphics();
        this.addChild(this.box);
        this.box.eventMode = 'dynamic';   
        this.box.alpha = 0.3;   
        this.box.visible = false; 

        const trailTexture = ResourceService.getTexture(ResourceList.MSC_TRAIL);

        this.rope = new PIXI.SimpleRope(trailTexture, this.points );
        this.addChild(this.rope);
        this.rope.rotation = 0;
        this.rope.alpha = 0.5;
        this.rope.visible = false;

        

        document.addEventListener('pointerdown', (event) => {
            const currenCoords = this.toLocal({x:event.clientX, y:event.clientY});
            this.slidePoints = [];
            const isInBoardArea = this.boradRect.contains(currenCoords.x, currenCoords.y);
            if (isInBoardArea) {
                this.startPoint = currenCoords;
            } else {
                this.startPoint = undefined;
            }
            

            setTimeout(() => {
                this.rope.visible = true;
            }, 200);

        });


        document.addEventListener('pointerup', (event) => {
            const currenCoords = this.toLocal({x:event.clientX, y:event.clientY});
            const averageDxDy = this.getAvarageDxDy(this.slidePoints);
            if (averageDxDy === undefined) return
            
            const slide = this.getSlideByDxDy(averageDxDy.x, averageDxDy.y);
            if (this.startPoint) {
                this.gamePlay.registerGesture(slide, this.startPoint, currenCoords);
            }

            setTimeout(() => {
                this.rope.visible = false;
            }, 700);
            
            this.slidePoints = null;
        });
        
        document.addEventListener('pointermove', (event) => {
            const currenCoords = this.toLocal({x:event.clientX, y:event.clientY});
            if (this.slidePoints) {
                this.slidePoints.push(currenCoords);
            }

            if (this.mouseposition) {
                const dx = currenCoords.x - this.mouseposition.x;
                const dy = currenCoords.y - this.mouseposition.y;
                const d = Math.sqrt(dx * dx + dy * dy);
                const a = Math.atan2(dy, dx);
                const aa = Utils.angleFromXY(this.mouseposition.x, this.mouseposition.y, currenCoords.x, currenCoords.y);
            }

            this.mouseposition = { x: currenCoords.x, y: currenCoords.y};
            
        });
        

        this.onResize = (w, h) => {
            this.w = w;
            this.h = h;
            this.box.clear();
            this.box.beginFill(0x00ff0);
            this.box.drawRect( 0, 0, w, h); 
            this.boradRect.width = w;
            this.boradRect.height = h;
        }

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){

            } 
            if (this.gameModel.gameState === EGameStates.stop){
                
            }
        };

        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);

        AppConfig.sizeUpdated.add(this.onResize);
        
        this.onResize();
    }

    /**
     * @access public
     */
    update() {

        if (!this.mouseposition) return;

        this.historyX.pop();
        this.historyX.unshift(this.mouseposition.x);
        this.historyY.pop();
        this.historyY.unshift(this.mouseposition.y);
        let angleSumm = 0;
        for (let i = 0; i < this.ropeSize; i++){
            const p = this.points[i];

            const ix = this.cubicInterpolation(this.historyX, (i / this.ropeSize) * this.historySize);
            const iy = this.cubicInterpolation(this.historyY, (i / this.ropeSize) * this.historySize);

            p.x = ix;
            p.y = iy;

            if (i > 0) {
                const prev = this.points[i - 1];
                const dx = p.x - prev.x;
                const dy = p.y - prev.y;
                const d = Math.sqrt(dx * dx + dy * dy);
                const a = Math.atan2(dy, dx);
                angleSumm += a;
    
            }
            
        }
        const angle = angleSumm / (this.ropeSize - 1);

    }


    isMobileDevice() {
        const isMobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        console.log("agent:" + navigator.userAgent + "; isMobile:" + isMobile);
        return isMobile
    }

    clipInput(k, arr)
    {
        if (k < 0) k = 0;
        if (k > arr.length - 1) k = arr.length - 1;

        return arr[k];
    }

    getTangent(k, factor, array)
    {
        return (factor * (this.clipInput(k + 1, array) - this.clipInput(k - 1, array))) / 2;
    }

    cubicInterpolation(array, t, tangentFactor = 1)
    {
        const k = Math.floor(t);
        const m = [this.getTangent(k, tangentFactor, array), this.getTangent(k + 1, tangentFactor, array)];
        const p = [this.clipInput(k, array), this.clipInput(k + 1, array)];

        t -= k;
        const t2 = t * t;
        const t3 = t * t2;

        return (2 * t3 - 3 * t2 + 1) * p[0] + (t3 - 2 * t2 + t) * m[0] + (-2 * t3 + 3 * t2) * p[1] + (t3 - t2) * m[1];
    }


    calculateAngle(capt, dx, dy) {
        const a = Math.atan2(dy, dx);
        const angle = a * 180 / Math.PI;
        console.log("-----", capt, dx, dy, a, angle);
    }

    testDirections() {
        this.calculateAngle ("r'", 5, 1);
        this.calculateAngle ("r'", 5, -1);
        this.calculateAngle ("r", 5, 0);
        this.calculateAngle ("b'", 1, 5);
        this.calculateAngle ("b'", -1, 5);
        this.calculateAngle ("b", 0, 5);
        this.calculateAngle ("l'", -5, 1);
        this.calculateAngle ("l'", -5, -1);
        this.calculateAngle ("l", 5, 0);
        this.calculateAngle ("t'", 1, -5);
        this.calculateAngle ("t'", -1, -5);
        this.calculateAngle ("t", 0, -5);
    }

    getSlideByDxDy(dx, dy, minDist = 2) {
        let slide = PointerGestures.TAP;
        const d = Math.sqrt(dx * dx + dy * dy);
        if (d < minDist){
            return PointerGestures.TAP;
        }
        if (Math.abs(dx) > Math.abs(dy)) {
            // Horizontal movement
            if (dx > 0) {
                slide = PointerGestures.SLIDE_RIGHT;
            } else {
                slide = PointerGestures.SLIDE_LEFT;
            }
        } else {
            // Vertical movement
            if (dy > 0) {
                slide = PointerGestures.SLIDE_DOWN;
            } else {
                slide = PointerGestures.SLIDE_UP;
            }
        }

        return slide;
    
    }

/*     getSlideByAngle(angle) {
        let slide = "none";
        if (angle > 45 && angle >= -45) {

        } else if (angle < -45 && angle > -135) {

        } ((angle < -135 || angle ) 
    
    } */

    getAvarageAngle(points) {
        if (points) {
            let angleSumm = 0;
            for (let i = 0; i < points.length; i++){
                const p = points[i];
                if (i > 0) {
                    const prev = points[i - 1];
                    const dx = p.x - prev.x;
                    const dy = p.y - prev.y;
                    const d = Math.sqrt(dx * dx + dy * dy);
                    const a = Math.atan2(dy, dx);
                    angleSumm += a;
                }   
            }
    
            const angle = angleSumm / (this.points.length - 1);
            return angle
        } else {
            return undefined
        }

    }
    
    getAvarageDxDy(points) {
        if (points) {
            let dxSumm = 0;
            let dySumm = 0;
            for (let i = 0; i < points.length; i++){
                const p = points[i];
                if (i > 0) {
                    const prev = points[i - 1];
                    const dx = p.x - prev.x;
                    const dy = p.y - prev.y;

                    dxSumm += dx;
                    dySumm += dy;
                }   
            }

            const point =  {x: dxSumm / (points.length - 1), y: dySumm / (points.length - 1)};
            return point
        } 
        return undefined
    }

    checkIfinAre


    
}
export default TouchArea