import * as PIXI from 'pixi.js'
import { Text } from 'pixi.js';
import SpriteCommon from '../common/SpriteCommon';
import ResourceList from '../../../services/ResourceList';
import ResourceService from '../../../services/ResourceService';
class NextShapeInfo  extends PIXI.Container{
    constructor(gameModel) {
        super();
        this.gameModel = gameModel;
        this.bg  = new PIXI.Graphics();
        this.drawBg();
        this.addChild(this.bg);

        this.label = new Text('SIGUIENTE', {
            fontFamily: 'MainBasketRun',
            fontSize: 12,
            fill: 0xB8F5F9,
            lineHeight:14,
            align: 'left'
        });

        this.addChild(this.label);
        this.label.y = 0;


        // const nextSghape = "j";
        // this.shape = new SpriteCommon(`${ResourceList.SHAPE_BASE}${nextSghape}.png`);
        this.shape = new PIXI.Sprite();

        this.addChild(this.shape);

        this.shape.anchor.set(0.5, 0.5);
        this.shape.x = this.bg.width / 2;
        this.shape.y = this.bg.height / 2 + 18;
        
        this.gameModel.shapeAdded.add((shapeID) => {
            //this.updateSHapeTexture(shapeID);
        });

        this.gameModel.nextShapeUpdated.add((shapeID) => {
            this.updateShapeTexture(shapeID);
        })

    }

    updateShapeTexture(shapeID) {
        if (!shapeID || shapeID === "") {
            this.shape.texture = null;
        } else {
            const texture = ResourceService.getTexture(`${ResourceList.SHAPE_BASE}${shapeID}.png`);
            this.shape.texture = texture;
        }
        
    }


    drawBg() {
        this.bg.clear();
        this.bg.lineStyle(1, 0x84d4de, 1);
        this.bg.beginFill(0x00BBC9);
        this.bg.drawRoundedRect( 0, 18, 61, 82, 16 );
    }
}

export default NextShapeInfo