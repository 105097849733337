import * as PIXI from 'pixi.js';
import ResourceService from "../../../services/ResourceService";
import { AppConfig } from '../../../config/AppConfig';

class GradientFill extends PIXI.Sprite  {
    constructor(color1, color2, angle) {
        super();
        this.color1 = color1;
        this.color2 = color2;
        this.texture = this.createGradTexture();

    }


    createGradTexture(){
        // adjust it if somehow you need better quality for very very big images
        const quality = 600;
        const canvas = document.createElement('canvas');

        canvas.width = 200;
        canvas.height = quality;

        const ctx = canvas.getContext('2d');

        // use canvas2d API to create gradient
        const grd = ctx.createLinearGradient(500, 0, 100, quality);


        grd.addColorStop(0.05, this.color1);
        grd.addColorStop(0.95, this.color2);

        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 200, quality);

        return PIXI.Texture.from(canvas);
    }


}

export default GradientFill

