import * as PIXI from 'pixi.js';
import ResourceService from '../../../services/ResourceService';
import { AppConfig } from '../../../config/AppConfig';

class FixiedTilingSprite extends PIXI.TilingSprite {
    constructor(resourceName, displaceX = 0, displaceY = 0) {
        const texture = ResourceService.getTexture(resourceName);
        super(
            texture,
            texture.width,
            texture.height
        );
        this.displaceX = displaceX;
        this.displaceY = displaceY;
        this.resourceName = resourceName;
        this.textureWidth = texture.width;
        this.textureHeight = texture.height;
        
    }

    /**
     * @access public
     * @param {number} width 
     * @param {number} height 
     * @param {'left' | 'right'} hAlign 
     * @param {'top' | 'bottom'} vAlign 
     */
    onResize(width, height, hAlign = 'center', vAlign = `center`) {
        return
        this.width = width
        this.height = height;
        if (hAlign === 'center') {
            this.tilePosition.x = (width % this.textureWidth) / 2 + this.displaceX;;
        } else if (hAlign === 'left')
        {
            this.tilePosition.x = (width - this.textureWidth);
        }
        if (vAlign === 'center') {
            this.tilePosition.y = (height % this.textureHeight) / 2;
        } else if (vAlign === 'bottom')
        {
            // this.tilePosition.y = (width - this.textureWidth);
            // this.tilePosition.y = height % this.textureHeight;
            this.tilePosition.y = (height % this.textureHeight);
        } else if (vAlign === 'top') {
            
        }

    }


}

export default FixiedTilingSprite;