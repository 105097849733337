import { AppConfig } from "../../config/AppConfig";
import GameModel, { EGameStates } from "../../model/GameModel";
import ResourceList from "../../services/ResourceList";
import SpriteCommon from "../components/common/SpriteCommon";
import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import FixiedTilingSprite from "../components/common/FixiedTilingSprite";

class GameOverScreen extends PIXI.Container {
    /**
     * @param {string} resurces 
     * @param {GameModel} gameModel 
     */
    constructor(resurces, gameModel, gameScreen){
        super();
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;
        this.bg = new FixiedTilingSprite(ResourceList.BG_CELLS, -200);
        // this.bg.anchor.set(0.5, 0.5);
        this.addChild(this.bg);
        this.win = new SpriteCommon(resurces);
        // this.win.anchor.set(0.5, 0.5);
        this.addChild (this.win);
        this.visible = false;
        this.eventMode = "dynamic";
        this.on('pointerdown', ()=> {
            this.gameModel.registerPlayAgainInteraction(true);

        });

        

        this.label = new Text('0', {
            fontFamily: 'MainBasketRun',
            fontSize: 54,
            fill: 0xffffff,
            lineHeight:50,
            align: 'left'
        });
        this.addChild(this.label);
        
        if (AppConfig.NO_PWA) {
            this.gameModel.gameStateUpdated.add(() => {
                if (this.gameModel.gameState === EGameStates.stop){
                    this.visible = true;
                    this.updtaeScoreValue(this.gameModel.scores);
                } else {
                    this.visible = false;
                    
                }
                if (this.gameModel.gameState === EGameStates.playing){
    
    
                }   
            });
        }

        this.onResize = () => {

            let gameWidth, gameHeight;     
            if (!AppConfig.SCALE_SCENE) {
                gameWidth = AppConfig.settings.gameWidth;
                gameHeight = AppConfig.settings.gameHeight;

                this.win.x = gameWidth / 2 - this.win.width / 2;
                this.win.y = gameHeight / 2 - this.win.height / 2;
    
                this.label.x = gameWidth / 2 - this.label.width / 2;
                this.label.y = gameHeight / 2 - 250;

                this.bg.width = gameWidth;
                this.bg.height = gameHeight;
                this.bg.x = - this.gameScreen.x;
                this.bg.y = 0;
            } else {
                const sc =  AppConfig.settings.gameHeight / AppConfig.base.HEIGHT;
                gameWidth = AppConfig.settings.gameWidth / sc;
                gameHeight = AppConfig.settings.gameHeight / sc;

                this.win.x = -10;
                this.win.y = 0;
    
                this.label.x = this.win.width / 2 - this.label.width / 2 - 10 ;
                this.label.y = gameHeight / 2 - 270;

                this.bg.width = gameWidth;
                this.bg.x = - (this.gameScreen.x / sc) ; 
            }

            // const { gameWidth, gameHeight } = AppConfig.settings;

  
        }
        AppConfig.sizeUpdated.add(this.onResize);
        this.onResize();
    }

    updtaeScoreValue(scoreValue) {
        this.label.text = scoreValue;
        this.onResize();
    }

    


    
}

export default GameOverScreen;
