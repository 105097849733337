import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import GameModel, { EGameStates } from '../../model/GameModel';
import SpriteCommon from "../components/common/SpriteCommon";
import ResourceList from "../../services/ResourceList";
import gsap from "gsap";
import { AppConfig } from '../../config/AppConfig';
import Countdown from '../components/CountDown';
import ScoreBallon from '../components/ScoreBallon';
import { SoundManager } from '../../services/SoundManager';
import TouchArea from '../components/TouchArea';
import PanelInfo from '../components/panels/PanelInfo';
import ReglesDOM from '../doms/ReglesDOM';
import EMessages from '../../services/EMessages';
import SoundData from '../../services/SoundData';
import ParticleEmitterFactory from '../components/effects/ParticleEmitterFactory';
import PanelControls from '../components/panels/PanelControls';
import GamePlay from '../../tetris/play/GamePlay';
import Keyboard from '../../tetris/utils/Keyboard';
import FullScreenGradient from '../components/common/FullScreenGradient';
import GameOverScreen from './GameOverScreen';



// const { gameWidth, gameHeight } = AppConfig.settings;
// const { animationSpeed, worldSize, conveyorY, conveyorWidth, zCartPosition, zDeep} = AppConfig.settings3D;
// const { levelMaxScores, newItemDelay } = AppConfig.gameSettings;


class GameScreen extends PIXI.Container {
    static isMouseDown = false;
    /**
     * @param {PIXI.Application} app
     * @param {GameModel} gameModel
     */
    constructor(app, gameModel) {
        super();
        this.app = app;
        this.gameModel = gameModel;
        this.soundManager = new SoundManager();

        this.key = new Keyboard();
        this.bg = new FullScreenGradient('#06BDCE', '#F70101', 0.5, this);
        this.gamePlay = new GamePlay(this.key, gameModel);
        this.gameModel.registerTetrisModel(this.gamePlay);

        this.topBanner = new SpriteCommon(ResourceList.BANNER_TOP);
        this.topBanner.anchor.set(0, 0);
        this.bottomBanner = new SpriteCommon(ResourceList.BANNER_BOTTOM);
        this.bottomBanner.anchor.set(0, 1);

        this.gamePlay.visible = true;
        this.touchArea  = new TouchArea(this.gameModel, this.gamePlay, this.gamePlay.width, this.gamePlay.height);
        this.scoreBallonsCont = new PIXI.Container();
        this.panelInfo = new PanelInfo(gameModel, this);
        this.emitter = ParticleEmitterFactory.initEmitter(this);
        this.topEmitter = ParticleEmitterFactory.initTopEmitter(this);

        this.countdown = new Countdown(this.gameModel, this);
        this.rulesDOM = new ReglesDOM(this.gameModel);
        this.panelControls = new PanelControls(gameModel, this);
        this.gameOverScreen = new GameOverScreen(ResourceList.GAME_OVER_SCREEN, this.gameModel, this);

        this.t = 0;
        this.vGaps = 0;

        this.onResize();

        this.addElements = () => {
          const { gameWidth, gameHeight } = AppConfig.settings;

            this.addChild(this.bg);
            this.addChild(this.topBanner);
            this.addChild(this.bottomBanner);
            this.addChild(this.panelInfo);
            this.addChild(this.gamePlay);
            this.addChild(this.emitter);
            this.addChild(this.scoreBallonsCont);
            this.addChild(this.touchArea);

            this.updateScores();

            this.addChild(this.countdown);
            this.addChild(this.panelControls);
            
            this.addChild(this.gameOverScreen);
            this.countdown.on('countdownStarted', () => {
                this.acitvateSounds();
            });
            this.countdown.on('countdownComplete', () => {
                this.gameModel.sendMessage(EMessages.SND_GAME_START)
                this.start();
            });
            

        };

        this.count = 1;

        this.updateScores = (item, scores) => {
            const { levelMaxScores } = AppConfig.gameSettings;
            this.addScoreBallon(item, 'scores', scores);
        };


        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.soundManager.play(SoundData.LOOP_BG_MUSIC);
                if (this.gameModel.isMagnet) this.soundManager.play(SoundData.LOOP_MAGNET);
                if (this.gameModel.speedUpFactor > 1) this.soundManager.play(SoundData.LOOP_SPEEDUPT);
            } else {
                this.soundManager.stopGameSounds();
            }

/*             if (this.gameModel.gameState === EGameStates.ready){
                this.gamePlay.enterWaiting(false);
            }

            if (this.gameModel.gameState === EGameStates.trainer){
                this.gamePlay.enterWaiting(true);
            } */

            if (this.gameModel.gameState === EGameStates.stop){
                this.soundManager.play(SoundData.MSC_FINISH);
            }
        };


        this.onGameRestarted = (item) => {
            // this.removeAllItems();
            this.countdown.resetCountDown();
            // this.presetItemsOnConveyor();
        };

        this.eventMode = `dynamic`;

        this.gameModel.scoreUpdated.add(this.updateScores);
        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameModel.gameRestarted.add(this.onGameRestarted);

        this.init();
        AppConfig.sizeUpdated.add(this.onResize.bind(this));
    }

    init() {
        this.addElements();
        this.step = 0;
        this.deltasum = 0;
        this.app.ticker.add((delta) => {
            if (this.gameModel.gameState !== EGameStates.playing && this.gameModel.gameState !== EGameStates.trainer) return
            this.gamePlay.update(delta);
            this.touchArea.update();
            this.t += delta;
            this.step++;
            this.deltasum += delta;
            if (this.step % 60 === 0) {
                this.deltasum = 0;
            }

        });

        window.addEventListener('keydown', (e) => {
            if (e.code === 'ArrowRight') {
                // this.gameModel.registerMoveCart(false);
            } else if (e.code === 'ArrowLeft') {
                // this.gameModel.registerMoveCart(true);
            };
        });

        window.addEventListener('mousedown', function(event) {
            if (event.button === 0) {
                GameScreen.isMouseDown = true;
            }
        });
        
        window.addEventListener('mouseup', function(event) {
            if (event.button === 0) {
                GameScreen.isMouseDown = false;
            }
        });

    };

    acitvateSounds() {
        this.soundManager.init();
    }

    start() {
        this.gameModel.resumeGame();
        this.gamePlay.enter({restart: true});
        this.gamePlay.update(0);
    }

    animate (delta = 0) {
    };

    onResize() {
        let gameWidth, gameHeight;
        if (!AppConfig.SCALE_SCENE) {
            gameWidth = AppConfig.settings.gameWidth;
            gameHeight = AppConfig.settings.gameHeight;
        } else {
            gameWidth = AppConfig.base.WIDTH;
            gameHeight = AppConfig.base.HEIGHT;
        }
        
        this.countdown.position.set(gameWidth / 2, gameHeight / 2);
        const bannersMargin = 15;
        const controlsHeight = 41;
        this.vGaps = Math.round((gameHeight - bannersMargin * 2  - this.topBanner.height -
            controlsHeight - this.gamePlay.height - this.bottomBanner.height) / 3);

        // this.vGaps = 0;

        const righPanelWidth = 50;

        this.topBanner.x = gameWidth / 2 - this.topBanner.width / 2;
        this.topBanner.y = bannersMargin;
        this.gamePlay.x = this.topBanner.x;
        // this.gamePlay.y = this.topBanner.y + this.topBanner.height + 60;
        this.gamePlay.y =  this.topBanner.y + this.topBanner.height + controlsHeight + this.vGaps * 2;

        this.bottomBanner.x = gameWidth / 2 - this.bottomBanner.width / 2;
        this.bottomBanner.y = this.gamePlay.y + this.gamePlay.height + this.vGaps + this.bottomBanner.height;

        this.touchArea.x = this.gamePlay.x;
        this.touchArea.y = this.gamePlay.y;
        this.touchArea.onResize(this.gamePlay.width, this.gamePlay.height);

        this.panelInfo.onResize();
        this.panelControls.onResize();
        this.countdown.onResize();




        if (AppConfig.SCALE_SCENE) {
            const sc = AppConfig.settings.yResponsiveFactor;
            this.scale.set(sc, sc);
            // this.x =  (AppConfig.settings.gameWidth / 2) * sc;
            this.x =  AppConfig.settings.gameWidth / 2 - (AppConfig.base.WIDTH / 2) * sc;
            this.bg.onResize();
        } 



    }
    

    /**
     * @param {*} item
     * @param {( 'scores' | 'time')} item
     * @param {*} value
     */
    addScoreBallon(item, type, value) {
        // const { gameWidth, gameHeight } = AppConfig.settings;

        const gameWidth = 363;
        const gameHeight = 708;


        if (item === undefined) return
        let point = item ? {x:item.x, y:item.y} : {x:gameWidth / 2, y:gameHeight / 2};
        this.fireworks.setImmiterPos(point.x, point.y);
        const pos = item?.posInRow !== undefined ? item?.posInRow : 0;
        const scoreBallon = new ScoreBallon(type, value, point, pos, item?.itemKind);
        this.scoreBallonsCont.addChild(scoreBallon);
        scoreBallon.on('finish', () => {
            this.scoreBallonsCont.removeChild(scoreBallon);
            scoreBallon.removeAllListeners('finish');
        });

        if (item?.itemKind?.id) {
            this.soundManager.playItemCautch(item.itemKind.id);
        } 
    }


}
export default GameScreen;
