import ResourceList from "../../../../services/ResourceList";
import SpriteCommon from "../../common/SpriteCommon";
import ItemsNumLabel from "./ItemNumLabel";
import {AdjustmentFilter} from '@pixi/filter-adjustment';

class RunPowerUpAbstract extends SpriteCommon{
    constructor(resurces, gameModel){
        super(resurces);
        this.gameModel = gameModel;
        this.eventMode = "dynamic";
        this.cursor = "pointer";
        this.shapeId = "";

        this._itemsNum = -1;

        this.on('pointerdown', ()=> {   
            if (this.itemsNum > 0) {
                this.runPowerUp();
            }    
            
        });
        
        this.addChildren();

        this.gameModel.powerUps.updated.add(() => {
            this.updateFromModel();
        });
        this.updateFromModel();
        // this.setDisabledStae();
    }

    get itemsNum() { return this._itemsNum }
    set itemsNum(value) { 
        if (value == this._itemsNum) return
        this.itemsnumCaption.setValue(value );
        this._itemsNum = value;
        this.updateLabelPos();
        if (value <= 0) {
            this.setDisabledStae();
        } else {
            this.setEnbledState();
        }
    }

    addChildren () {
        this.itemsnumCaption = new ItemsNumLabel();
        const digits = Math.floor(Math.random() * 4);
        

        this.itemsNum = 0;
        // this.itemsnumCaption.setValue(Math.floor(Math.random() * Math.pow(10, digits)));
        // this.itemsNum = Math.floor(Math.random() * Math.pow(10, digits));
        // this.itemsNum = Math.floor(Math.random() * 100);
        this.addChild(this.itemsnumCaption);
        this.updateLabelPos();
    }

    updateLabelPos(){
        this.itemsnumCaption.x = this.width * 0 - this.itemsnumCaption.width;
        this.itemsnumCaption.y = - this.height * 0.6 - this.itemsnumCaption.height;
    }

    runPowerUp () {
        this.gameModel.nextShape = this.shapeId;
        if (this.itemsNum > 0) {
            this.itemsNum --;
        }
    }

    updateFromModel() {

    }

    setEnbledState() {
        this.filters = [];
        this.cursor = "pointer";
    }

    
    setDisabledStae() {
        const ajust = new AdjustmentFilter();
        ajust.saturation = 0.3;
        this.filters = [ajust];
        this.cursor = "defult";
    }
}



export default RunPowerUpAbstract;