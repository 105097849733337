import { AppConfig } from "../config/AppConfig";
import { MiniSignal } from "mini-signals";
import CommunicationService from "../services/CommunicationService";
import GameStat from "./GameStat";
import EMessages from "../services/EMessages";
import UUIDLoader from "../services/UUIDLoader";
import TetronimoSpawner from "../tetris/play/TetronimoSpawner";
import GamePlay from "../tetris/play/GamePlay";
import powerUpsModel from "./PowerUpsModel";
import PowerUpsModel from "./PowerUpsModel";

export const EGameStates = Object.freeze({"stop":1, "playing":2, "pause":3, "ready":4, "trainer":5});

const { magnetItemsCount} = AppConfig.settings;

class GameModel {
    static _instance;
    static get instance() {
        return GameModel._instance;
    }
    constructor() {
        const { initialSpeed, initialTimeLeft } = AppConfig.gameSettings;
        GameModel._instance = this;
        this.communictionService = new CommunicationService(this);
        this.scoreUpdated = new MiniSignal();
        this.gameStateUpdated = new MiniSignal();
        this.gameRestarted = new MiniSignal();
        this.rulesStatusUpdated = new MiniSignal();
        this.shapeAdded = new MiniSignal();
        this.nextShapeUpdated = new MiniSignal();
        this.onGesture = new MiniSignal();
        // this.speedUpdated = new MiniSignal();
        this.powerUps = new PowerUpsModel();
        

        this._gameState = EGameStates.ready;
        this.gameStat = new GameStat();
        this._speed = initialSpeed;
        this._speedUpFactor = 1;
        this._scores = 0;
        this._balance = 0;
        this._showRules = false;
        this.emtpyRowsCount = 0;
        this._currentShape = "";
        this._nextShape = "";
    }

    get scores() { return this._scores }
    set scores(value) {
        if (value == this._scores) return
        const increment = value - this._scores;
        // this._scores = value;
        if (value >= 0) {
            this._scores = value;
        } else {
            this._scores = 0;
        }
        if (value >= 4000) {
            this._scores = 4000;
            this.finishGame();
        } 
        this.scoreUpdated.dispatch(this.lastItem, increment);
    }

    get balance() { return this._balance }
    set balance(value) {
        if (value == this._balance) return
        this._balance = value;
        this.scoreUpdated.dispatch();
    }

    get showRules() { return this._showRules }
    set showRules(value) { 
        if (value == this._showRules) return
        this._showRules = value; 
        this.rulesStatusUpdated.dispatch(value); 
    }

    get currentShape() { return this._currentShape }
    set currentShape(value) { 
        // if (value == this._currentShape) return
        this._currentShape = value;
        this.shapeAdded.dispatch(value);
        this.gameStat.storeСaughtItem(value);
    }

    get nextShape() { return this._nextShape }
    set nextShape(value) { 
        // if (value == this._nextShape) return
        this._nextShape = value;
        this.nextShapeUpdated.dispatch(value);
    }

    get gameState() { return this._gameState }
    set gameState(value) { 
        if (value == this._gameState) return
        this._gameState = value; 
        this.gameStateUpdated.dispatch();
    }

    init() {
    }

    getParamsByTime(currentTime, configObj) {
        const keys = Object.keys(configObj).map(Number).sort((a, b) => a - b);
        const key = keys.find(interval => interval > currentTime);
        return key ? configObj[key] : configObj['infinity'];
    }

    getParamsByCount(count, configObj) {
        const keys = Object.keys(configObj).map(Number).sort((a, b) => a - b);
        const key = keys.find(interval => interval > count);
        return key ? configObj[key] : configObj['infinity'];
    }

    /**
     *  @param      {Object} probObj
     *              the object with distribution of probabilities in perscetns
     *              {prop1:10, prop2:20, prop3:70}. It must be 100 in summ
     *  @param      {boolean} usePercents=true
     *              determine if the values in objects are defined as percents but
     *  @returns    {string}
     *              propname
     */
    getPropertyByProbability(probObj, usePercents = true) {
        const randomNumber = Math.floor(Math.random() * 100.1);
        const percentFactor = usePercents ? 1 : 100;
        let cumulativeProbability = 0;
        for (const row in probObj) {
            if (probObj.hasOwnProperty(row)) {
                cumulativeProbability += (probObj[row] * percentFactor);
                if (randomNumber <= cumulativeProbability) {
                    return row
                }
            }
        }


        // Default to the last row if the random number exceeds the cumulative probability
        const lastKey = Object.keys(probObj)[Object.keys(probObj).length - 1];
        // return Object.keys(probObj).length - 1;
        return lastKey;
    }



    /***************************
     * Controller
     **************************/

    /**
     * @param {GamePlay} gamePlay 
     */
    registerTetrisModel(gamePlay) {
        this.gamePlay = gamePlay;
        this.shapeSpowner = gamePlay.spawner;
        this.nextShape = this.shapeSpowner.getNextShapeType();
        this.currentShape = this.shapeSpowner.getNextShapeType();
    }
    
    /**
     * @param {string} shape -current tetromino id "i,j,k..
     */
    registerCurrentShape(shape) {
        this.currentShape = shape;
    }

    /**
     * @param {string} shape -current tetromino id "i,j,k..
     */
    registerNextShape(shape) {
            this.nextShape = shape;
    }

    /**
     * Wrap message to object with {eventName:'eventName'} and sends to the parent
     * @access public
     * @param {string} message
     * @param {Object} data
     */
    sendMessage(message, data){
        this.communictionService.prepareAndSendEvent(message, data);
    }

    /**
     * @access public
     */
    resetGame() {
        const { initialSpeed, initialTimeLeft } = AppConfig.gameSettings;

        this.pauseGame();
        this.gameState = EGameStates.ready;
        this.gameStat.resetStats();
        this.scores = 0;
        this.gameRestarted.dispatch();
    }

    /**
     * @access public
     */
    startGame() {
        this.sendMessage(EMessages.SND_GAME_START);
        this.resumeGame();

    }

    /**
     * @access public
     */
    resumeGame() {
        // this.timeSpent = 0;
        this.gameState = EGameStates.playing;
        this.gameStat.resetStats();
        if (this.intervalId) clearInterval(this.intervalId)
        this.intervalId = setInterval(() => {
            // this.updateTime();
        }, 1000);

    }

    /**
     * @access public
     */
    finishGame() {
        this.pauseGame();
        this.gameState = EGameStates.stop;
        const sendData = {
            timeSpent: this.timeSpent,
            currentScore: this.scores,
            gameImage: this.gameStat.getItemsHistoryString(),
            items: this.gameStat.getItemsStat()
        };
        const uuid = '' + new UUIDLoader((JSON.stringify(sendData).length).toString(14), sendData, "f0227a92774111eeb9620242ac120002")

        const message = {
            eventName: EMessages.SND_GAME_END,
            score: this.scores,
            gameId:this.communictionService.gameId,
            uuid:uuid,
            gameData: {
                timeSpent: this.timeSpent,
                score: this.scores,
                gameImage: sendData.gameImage,
                items: sendData.items
            }
        };

        console.log(message);
        this.communictionService.sendMessage(message);
    }

    /**
     * @access public
     */
    pauseGame() {
        this.gameState = EGameStates.pause;
        clearInterval(this.intervalId);
        // this.gameStateUpdated.dispatch();

    }

    /**
     * @access public
     */
    startTrainer() {
        this.gameState = EGameStates.trainer;

    }

    /**
     * @access public
     */
    registerCloseInteraction() {
        if (this.gameState === EGameStates.playing) {
            this.sendMessage(EMessages.SND_GLOSE);
        } else {
            this.sendMessage(EMessages.SND_ON_EXIT);
        }
        
        if (this.gameState === EGameStates.playing){
            this.pauseGame();
        } else if (this.gameState === EGameStates.pause) {
            this.resumeGame();
        };
    }

    /**
     * @access public
     * @param {boolean} force
     */
    registerPlayAgainInteraction(force) {
        if (this.gameState === EGameStates.stop || force) {
            // this.resumeGame();
            this.resetGame();
        };
    }

    /**
     * @access public { GamePlay }
     * @param {boolean} gesture
     */
    reportGesture(gesture) {
        this.onGesture.dispatch(gesture);
    }

}
export default GameModel;
