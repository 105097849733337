
import { MiniSignal } from "mini-signals";
import { SHAPE_COLORS } from "../tetris/play/Tetromino";

class AppConfig {

    static sizeUpdated = new MiniSignal();
    static cameraUpdated = new MiniSignal();
    static isDebug = true;
    static SHAPE_TYPES = Object.keys(SHAPE_COLORS);
    static hidelogs = false;
    static SCALE_SCENE = true;
    // static SCALE_SCENE = !window.noscale;
    static NO_PWA = window.nopwa;
    static FORCE_SKIP_TUTORIAL = false;
    


    /** 
     * @readonly
     */
    static base = {
        DISEING_WIDTH: 363,
        DESIGN_HEIGHT: 720,
        WIDTH: 363,
        HEIGHT: 720,

    }

    /** 
     * @readonly
     */
    static settings = {
        gameWidth: AppConfig.base.WIDTH,
        gameHeight: AppConfig.base.HEIGHT,
        gameMinWidth: AppConfig.base.WIDTH,
        xResponsiveFactor: 1,
        yResponsiveFactor: 1,
        centerX: AppConfig.baseWidth / 2
    }


    static updateSize (w, h) {
        AppConfig.base.WIDTH = AppConfig.base.DISEING_WIDTH;
        AppConfig.base.HEIGHT = AppConfig.base.DESIGN_HEIGHT;
        const designAspectRatio = AppConfig.base.DISEING_WIDTH / AppConfig.base.DESIGN_HEIGHT;
        const currentApectRatio = w / h;
        if (currentApectRatio < designAspectRatio) {
            AppConfig.base.HEIGHT = AppConfig.base.WIDTH / currentApectRatio;
        }
        AppConfig.settings.gameWidth = w;
        AppConfig.settings.gameHeight = h;
        AppConfig.settings.yResponsiveFactor = h / AppConfig.base.HEIGHT;
        AppConfig.settings.xResponsiveFactor = AppConfig.settings.yResponsiveFactor;

        AppConfig.sizeUpdated.dispatch();    
    }


    /** 
     * @readonly
     */
    static gameSettings = {
        // counDownStepDuration:0.01
        counDownStepDuration:0.5,
        shapesOrder: "ijlostzjlostzjlostzjlostz",
        cellScoreValue: 1
    };

}

if (AppConfig.NO_PWA) {
    AppConfig.gameSettings.cellScoreValue = 100;
} else {
    AppConfig.gameSettings.cellScoreValue = 20;
}

export { AppConfig };