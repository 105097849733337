import { AppConfig } from '../../config/AppConfig';
import GameModel, { EGameStates } from '../../model/GameModel';
import DebugService from '../../services/DebugService';
import PointerGestures from '../../services/PointerGestures';
import ResourceList from '../../services/ResourceList';
import GradientFill from '../../view/components/common/GradientFill';
import SpriteCommon from '../../view/components/common/SpriteCommon';
import config from '../config';
import State from '../utils/State';
import Board from './Board';
import Renderer from './Renderer';
import TetronimoSpawner from './TetronimoSpawner';


export default class GamePlay extends State {
    /**
     * GamePlay state provides main game logic
     * @param {Keyboard} key
     * @param {GameModel} gameModel
     */
    constructor(key, gameModel) {
        super();
        
        this.key = key;
        this.gameModel = gameModel;
        
        this.board = null;
        this.spawner = new TetronimoSpawner();
        // this.spawner = null;
        this.tetromino = null;

        this.boardBG = new GradientFill('#00BBC9', '#B20101', 0.5);
        this.boardBG.width = 200;
        this.boardBG.height = 200;
        this.addChild(this.boardBG);

        
        this.renderer = new Renderer(config.game.rows, config.game.cols, config.game.hiddenRows, config.display.blockSize);
        this.addChild(this.renderer);

        AppConfig.sizeUpdated.add(this.onResize.bind(this));
        this.onResize();

        this.gameModel.gameStateUpdated.add(() => {
            if (this.gameModel.gameState === EGameStates.stop){

            } else {

            }
            if (this.gameModel.gameState === EGameStates.playing){


            }   
            if (this.gameModel.gameState === EGameStates.trainer){
                this.enterWaiting(true);
                this.update(0);

            }   
            if (this.gameModel.gameState === EGameStates.ready){
                this.enterWaiting(false);
                this.update(0);

            }  
        });

        this.gameModel.gameRestarted.add(() => {

        });
    }
    
    /**
     * Reset game
     */
    enter(opts) {
        if (opts.restart || this.board == null) {
            this.board = new Board(config.game.rows + config.game.hiddenRows, config.game.cols);
            this.spawner = new TetronimoSpawner();

            this.tetromino = null;
            this.tetrominoFallSpeed = config.game.fallSpeed;;
            this.tetrominoFallSpeedMin = config.game.fallSpeedMin;
            this.tetrominoFallSpeedupStep = config.game.fallSpeedupStep;
            this.tetrominoFallSpeedupDelay = config.game.fallSpeedupDelay;
            this.tetrominoDropModifier = config.game.dropModifier;

            this.tetrominoFallTimer = this.tetrominoFallSpeed;
            this.tetrominoFallSpeedupTimer = this.tetrominoFallSpeedupDelay;

            this.rowsCleared = 0;
            this.score = 0;

            this.spawnTetromino();
        }
    }

    /**
     * Reset game
     */
    enterWaiting(isTrainer = false) {

        this.board = new Board(config.game.rows + config.game.hiddenRows, config.game.cols);
        this.spawner = new TetronimoSpawner();

        this.tetromino = null;
        if (isTrainer) {
            this.tetrominoFallSpeed = config.game.fallSpeed * 1;
        } else {
            this.tetrominoFallSpeed = config.game.fallSpeed * 1;
        }
        
        this.tetrominoFallSpeedMin = config.game.fallSpeedMin;
        if (isTrainer) {
            this.tetrominoFallSpeedupStep = config.game.fallSpeedupStep * 1;
            this.tetrominoFallSpeedupDelay = config.game.fallSpeedupDelay / 1;
        }

        this.tetrominoDropModifier = config.game.dropModifier;

        this.tetrominoFallTimer = this.tetrominoFallSpeed;
        this.tetrominoFallSpeedupTimer = this.tetrominoFallSpeedupDelay;

        this.rowsCleared = 0;
        this.score = 0;

        if (isTrainer) {
            this.spawnTetromino();
        }
        
        

    }

    set visible (value) {
        super.visible = value;
    }
    
    /**
     * Main update funcion
     * @param {Number} dt pixi timer deltaTime
     */
    update(dt) {

        if (this.tetromino) {
            if (this.gameModel.gameState === EGameStates.trainer) {
                this.updateTetrominoInTraine();
            } else {
                this.updateTetromino();
            }
            
        }        
        this.renderer.updateFromBoard(this.board);
        this.renderer.updateFromTetromino(this.tetromino);
    }
    
    /**
     * Spawn new active tetromino and test for end game condition
     */
    spawnTetromino() {
        // this.tetromino = this.spawner.spawn();
        let currentShape = this.gameModel.nextShape;
        if (!currentShape || currentShape  === "") {
            currentShape = this.spawner.getNextShapeType();
        }
        this.tetromino = this.spawner.getNewTetrominoByID(currentShape);

        const nextShape = this.spawner.getNextShapeType();
        this.gameModel.registerNextShape(nextShape);
        this.gameModel.registerCurrentShape(currentShape);

        
        this.tetromino.row = 0;
        this.tetromino.col = this.board.cols / 2 - 2;
        
        if (this.board.collides(this.tetromino.absolutePos(0, 0))) {
            this.lockTetromino();
            this.gameOver();
        }
    }
    
    /**
     * merge active tetromino with board
     */
    lockTetromino() {

        // this.board.slime(4);
        let absolutePos;
        // let fullRows = this.board.setAll(this.tetromino.absolutePos(), this.tetromino.color);
        
        let slimeCells = 0;
        // const slimeCol = this.tetromino.col + this.get
        let fullRows;
        if (this.tetromino.shapeType == 'a') {
        

            const tetrominoBRSize = this.tetromino.getBottomRightBorder();
            const slimeCol = this.tetromino.col + tetrominoBRSize.x - 1;
            const slimeRow = this.tetromino.row + tetrominoBRSize.y - 1;

            absolutePos = this.board.slime(slimeRow, slimeCol);
            fullRows = this.board.setAll(absolutePos, "green");
        } else {
            absolutePos = this.tetromino.absolutePos();
            fullRows = this.board.setAll(absolutePos, this.tetromino.color);
        }        

        if (fullRows.length > 0) {
            this.board.cleanRows(fullRows);
        }


        if (fullRows.length > 0 ) {
            this.updateScore(fullRows.length);
        }

        this.tetromino = null;
    }
    
    /**
     * handle game ending
     */
    gameOver() {
        console.log("GameOver");
        this.gameModel.finishGame();
        return

    }


    /**
     * @param{x: number, y:number} pointStart
     * @param{x: number, y:number} pointEnd
     * @param {string} gesture 
     */

    registerGesture(gesture, pointStart, pointEnd) {
        if (!this.tetromino) return
        const tetrominoSize = this.tetromino.getBottomRightBorder();
        const tetromiinoFreeSpace = this.tetromino.getFreeSpace(tetrominoSize.x, tetrominoSize.y);
        const pointStartGrid = this.getLocalPointBy(pointStart);
        const pointEndGrid = this.getLocalPointBy(pointEnd);
        const distanceGrid = {x:pointEndGrid.x - pointStartGrid.x, y:pointEndGrid.y - pointStartGrid.y};
        if (gesture == PointerGestures.SLIDE_LEFT || gesture == PointerGestures.SLIDE_RIGHT){
            distanceGrid.x = Math.ceil(distanceGrid.x / 2);
            distanceGrid.y = Math.ceil(distanceGrid.y / 2);
        }

        // const maxDist = this.getMaxDistanceFromPoint(distanceGrid);
        

        let distance = 0;
        let closestDistance;
        

        // const termiSizeX = this.tetromino
        switch (gesture) {
            case PointerGestures.SLIDE_LEFT:
                const maxLeftDistance = this.tetromino.col + tetromiinoFreeSpace.x;
                
                distance = Math.abs(distanceGrid.x);
                if (distance > maxLeftDistance) distance = maxLeftDistance;

                closestDistance = this.board.distanseToClosestFromShape(this.tetromino, distance, gesture);
                
                if (closestDistance > 0) {
                    this.tetromino.col -= closestDistance;
                    this.gameModel.reportGesture(gesture);
                }

                break;
            case PointerGestures.SLIDE_RIGHT:
                const maxRightDistanceX = this.board.cols - this.tetromino.col - tetrominoSize.x; //x is negative when it's right slide

                distance = distanceGrid.x;
                if (distance > maxRightDistanceX) distance = maxRightDistanceX;
                closestDistance = this.board.distanseToClosestFromShape(this.tetromino, distance, gesture);
                if (closestDistance > 0) {
                    this.tetromino.col += closestDistance;
                    this.gameModel.reportGesture(gesture);
                }
                
                break;
            case PointerGestures.SLIDE_UP:
   
                
                break;
            case PointerGestures.SLIDE_DOWN:
                const maxDownDistance = this.board.rows - this.tetromino.row - tetrominoSize.y; 

                distance = distanceGrid.y;
                if (distance > maxDownDistance) distance = maxDownDistance;
                closestDistance = this.board.distanseToClosestFromShape(this.tetromino, distance, gesture);
                if (closestDistance > 0) {
                    this.tetromino.row += closestDistance;
                    this.gameModel.reportGesture(gesture);
                }

                break;
            case PointerGestures.TAP:
                if (!this.board.collides(this.tetromino.absolutePos(0, 0, true))) {
                    this.tetromino.rotate();
                } else if (!this.board.collides(this.tetromino.absolutePos(0, -1, true))) {
                    --this.tetromino.col;
                    this.tetromino.rotate();
                } else if (!this.board.collides(this.tetromino.absolutePos(0, 1, true))) {
                    ++this.tetromino.col;
                    this.tetromino.rotate();
                }
                this.gameModel.reportGesture(gesture);
                break;    
            
            }
    }

    /**
     * Update terominos falling and handle user input
     */
    updateTetrominoInTraine() {

        if (this.key.up.trigger()) {
            if (!this.board.collides(this.tetromino.absolutePos(0, 0, true))) {
                this.tetromino.rotate();
            } else if (!this.board.collides(this.tetromino.absolutePos(0, -1, true))) {
                --this.tetromino.col;
                this.tetromino.rotate();
            } else if (!this.board.collides(this.tetromino.absolutePos(0, 1, true))) {
                ++this.tetromino.col;
                this.tetromino.rotate();
            }
        }
        
        if (this.key.left.trigger() && !this.board.collides(this.tetromino.absolutePos(0, -1))) {
            --this.tetromino.col;
        }
        if (this.key.right.trigger() && !this.board.collides(this.tetromino.absolutePos(0, 1))) {
            ++this.tetromino.col;
        }
         
        let tickMod = this.key.down.pressed ? this.tetrominoDropModifier : 1;
        if ((--this.tetrominoFallSpeedupTimer) <= 0) {
            this.tetrominoFallSpeed = Math.max(this.tetrominoFallSpeedMin, this.tetrominoFallSpeed - this.tetrominoFallSpeedupStep);
            this.tetrominoFallSpeedupTimer = this.tetrominoFallSpeedupDelay;
            console.log('speed: ', this.tetrominoFallSpeed);
        }
        if ((this.tetrominoFallTimer -= tickMod) <= 0) {
            const bottomBorder = this.tetromino.getBottomRightBorder().y;
            if (bottomBorder + this.tetromino.row >= this.board.rows - 1) {
                // this.tetromino.row = 0;
                this.spawnTetromino();
            } else {
                ++this.tetromino.row;
            }
            
            this.tetrominoFallTimer = this.tetrominoFallSpeed;
            

/*             if (this.board.collides(this.tetromino.absolutePos(1, 0))) {
                this.lockTetromino();
                this.spawnTetromino();
            } else {
                ++this.tetromino.row;
                this.tetrominoFallTimer = this.tetrominoFallSpeed;
            } */
        }

    }
    
    /**
     * Update terominos falling and handle user input
     */
    updateTetromino() {
        if (this.key.up.trigger()) {
            if (!this.board.collides(this.tetromino.absolutePos(0, 0, true))) {
                this.tetromino.rotate();
            } else if (!this.board.collides(this.tetromino.absolutePos(0, -1, true))) {
                --this.tetromino.col;
                this.tetromino.rotate();
            } else if (!this.board.collides(this.tetromino.absolutePos(0, 1, true))) {
                ++this.tetromino.col;
                this.tetromino.rotate();
            }
        }
        
        if (this.key.left.trigger() && !this.board.collides(this.tetromino.absolutePos(0, -1))) {
            --this.tetromino.col;
        }
        if (this.key.right.trigger() && !this.board.collides(this.tetromino.absolutePos(0, 1))) {
            ++this.tetromino.col;
        }
         
        let tickMod = this.key.down.pressed ? this.tetrominoDropModifier : 1;
        if ((--this.tetrominoFallSpeedupTimer) <= 0) {
            this.tetrominoFallSpeed = Math.max(this.tetrominoFallSpeedMin, this.tetrominoFallSpeed - this.tetrominoFallSpeedupStep);
            this.tetrominoFallSpeedupTimer = this.tetrominoFallSpeedupDelay;
            console.log('speed: ', this.tetrominoFallSpeed);
        }
        if ((this.tetrominoFallTimer -= tickMod) <= 0) {
            if (this.board.collides(this.tetromino.absolutePos(1, 0))) {
                this.lockTetromino();
                this.spawnTetromino();
            } else {
                ++this.tetromino.row;
                this.tetrominoFallTimer = this.tetrominoFallSpeed;
            }
        }
    }
    
    /**
     * Update score based on number of cleared rows
     * @param {Number} rows count of rows cleared in one move
     */
    updateScore(rows) {
        this.rowsCleared += rows;
        this.score += rows * rows * AppConfig.gameSettings.cellScoreValue * this.board.cols;
        this.gameModel.scores = this.score;
    }

    onResize() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        this.boardBG.width = this.renderer.width;
        this.boardBG.height = this.renderer.height;
        this.boardBG.x = this.renderer.x;
        this.boardBG.y = this.renderer.y;

    }

    /**
     * @point {x:number, y:number}
     * @returns {x:number, y:number}
     */
    getLocalPointBy(point){
        const gridX = Math.floor(point.x / config.display.blockSize);
        const gridY = Math.floor(point.y / config.display.blockSize) + config.game.hiddenRows;
        return {x: gridX, y: gridY};

    }

    /**
     * @pos {x:number, y:number}
     * @returns {x:number, y:number}
     */
/*     getMaxDistanceFromPoint(pos) {
        const maxX = this.board.cols  - pos.x;
        const maxY = this.board.rows  - pos.y;
        return {x:maxX, y:maxY}
    }
 */
}
