import { MiniSignal } from "mini-signals";
import { AppConfig } from "../config/AppConfig";
import GameModel, { EGameStates } from "../model/GameModel";
import EMessages from "./EMessages";
import DebugService from "./DebugService";

class CommunicationService {
    /**
     * 
     * @param {GameModel} gameModel 
     */
    constructor(gameModel) {
        this.gameModel = gameModel;
        this.gameId;
        this.availableGames;
        this.isCompleted = false;
        this.shouldShowTutorial = false;
        this.tutorialStatusUpdated = new MiniSignal();
        this.subscribeMessages();
    }



    subscribeMessages() {
        window.addEventListener('message', event => {
            this.logMessage(event);
            if (event?.data?.eventName) {
                const eventName = event?.data?.eventName;
                if (eventName.eventName && eventName.eventName === EMessages.RCV_GAME_INFO) {
                    this.gameId = eventName.gameId;
                    this.availableGames = eventName.availableGames;
                    this.personalRecord = eventName.personalRecord;
                    this.period = eventName.period;
                    this.sponsors = eventName.sponsors;
                    this.isCompleted = this.parseBoolean(eventName.isCompleted);
                    this.shouldShowTutorial = this.parseBoolean(eventName.tutorial);
                    this.tutorialStatusUpdated.dispatch(this.shouldShowTutorial);
                    this.gameModel.balance = eventName.balance;
                    const powerUps = eventName.powerUps;
                    // const {block1x, block5x, slime} = powerUps;

                    // this.gameModel.powerUps.update(powerUps.block1x, powerUps.block5x, powerUps.slime);
                    this.gameModel.powerUps.update(
                        powerUps.block1x ?? 0,
                        powerUps.block5x ?? 0,
                        powerUps.slime ?? 0
                      );


                    // console.log("received event :" + event?.data);
                } else {
                    switch (eventName) {
                        case EMessages.RCV_GAME_RGISTERD:
                            const banner = event?.data?.banner;
                            
                            if (this.gameModel.gameState != EGameStates.playing 
                                && this.gameModel.gameState != EGameStates.pause) {
                                    this.shouldShowTutorial = event?.data?.tutorial;
                                    this.tutorialStatusUpdated.dispatch(this.shouldShowTutorial);
                            }

                            this.logMessage("received event :" + event.data);
                            break;
                        case EMessages.RCV_SHOW_PRELOAD_ANIMATION:
                            this.logMessage("received event :" + EMessages.RCV_SHOW_PRELOAD_ANIMATION);
                            break;
                        case EMessages.RCV_CLOSE_REFUSE:
                            this.logMessage("received event :" + EMessages.RCV_CLOSE_REFUSE);
                            if (this.gameModel.gameState == EGameStates.pause) {
                                this.gameModel.resumeGame();
                            }
                            break;
                        case EMessages.RCV_USER_STOP_GAME:
                            this.logMessage("received event :" + EMessages.RCV_USER_STOP_GAME);
                            this.gameModel.finishGame();
                            break;
                        case EMessages.PLAY_AGAIN:
                            this.logMessage("received event :" + EMessages.RCV_USER_STOP_GAME);
                            this.gameModel.resetGame();
                            break;

                        case EMessages.SND_ALL_LOADED:
                        case EMessages.SND_TUTORIAL_END:
                        case EMessages.SND_GAME_START:
                        case EMessages.SND_GLOSE:
                        case EMessages.SND_GAME_END:

                        case EMessages.SND_EXT_SECOND_PAGE_INIT:
                        case EMessages.SND_EXT_WEBGAME_CLOSE:
                        case EMessages.SND_EXT_CLICK_BANNER:
                            this.logMessage("Self messages :" + eventName);
                            break;
                        default:
                            const error = new Error("unregistered event", "Received unkown event from the wrapper")
                            this.logMessage(error.message);
                    }
                }
            } else {
                const error = new Error("unregistered event", "Received unkown event from the wrapper");
                this.logMessage(error.message);
            }

            if (AppConfig.isDebug) {
                this.displayDebugObject(event.data);
            }
            return

        });
    }

    /**
     * @access public
     * @param {string} eventName 
     */
    prepareAndSendEvent(eventName) {
        const message = {
            eventName: eventName
        }
        this.sendMessage(message);
    }

    /**
     * @access public
     * @param {string} message 
     * @param {Object} data 
     */
    sendMessage(message) {
        // console.log("Sent message: " + message);
        this.logMessage(message, "Sent message:")
        window.parent.postMessage(message, '*');
    }

    displayDebugObject(obj, type = "event") {
        DebugService.log(obj, type);
    }

    parseBoolean(value) { return (String(value).toLowerCase() === 'true');}

    logMessage(message, caption = "") {
        if (AppConfig.hidelogs === true) return;
        console.log(caption, message);
    }


}

export default CommunicationService