import { MiniSignal } from "mini-signals";

class PowerUpsModel {
    constructor() {
        this.updated = new MiniSignal();
        this.block1x = 0;
        this.block5x = 0;
        this.slime = 0;
    }

    update(block1x, block5x, slime) {
        this.block1x = block1x;
        this.block5x = block5x;
        this.slime = slime;
        this.updated.dispatch();
    }
    
    
    

}

export default PowerUpsModel;