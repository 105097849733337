import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import ResourceList from '../../../../services/ResourceList';
import ResourceService from '../../../../services/ResourceService';

class ItemsNumLabel extends PIXI.Container {
    constructor() {
        super();

        this.bg = new PIXI.NineSlicePlane(ResourceService.getTexture(ResourceList.CTRL_ITEMS_NUM_BG), 4, 4, 4, 4);
        this.addChild(this.bg);  
        this.value = 0;

        this.label = new Text(this.value + "", {
            fontFamily: 'MainBasketRun',
            fontSize: 14,
            fill: 0xFFB800,
            lineHeight:14,
            align: 'right',
            dropShadow: true,
            dropShadowColor: '#052135',
            dropShadowDistance: 1,
            dropShadowBlur: 0,
            dropShadowAngle: 2 * Math.PI / 6,
        });


        // this.addChild(this.labelShadow);
        this.addChild(this.label);

        this.label.x = 4;
        this.label.y = -2;

        this.updateBbByValue();

    }

    setValue(value) {
        this.value = value;
        this.label.text = this.value;
        this.updateBbByValue();

    }

    updateBbByValue() {
        this.bg.width = this.label.width + 8;
        // this.bg.height = this.label.he + 8;
    }

}

export default ItemsNumLabel